import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./FinishCard.scss";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useContext } from "react";
import CardDataContext from "./CardData.provider";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authLogin } from "../../../redux/actions/auth.actions";
import { TailSpin } from "react-loader-spinner";
import { createCard, updateCard } from "../../../api/card";
import { useSnackbar } from "notistack";
import { checkUser } from "../../../api/auth";
import logo from "../../../assets/logo.png";
import logoSmall from "../../../assets/logoSmall.png";

const FinishModal = ({ setFinishing, setIsCreating, isEditing }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { cardData, resetAll } = useContext(CardDataContext);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const actualizarUser = async () => {
    const response = await checkUser();
    if (response.status) {
      dispatch(
        authLogin({
          ...response.data.userToSend,
          userType: response.data.userType,
          token: response.data.token,
        })
      );
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let card = {
      ...cardData,
      name: cardData.name || auth.name,
      email: auth.email,
    };
    if (card.socialMedia && !card.socialMedia[0]) delete card.socialMedia;
    const formData = new FormData();
    formData.append("data", JSON.stringify({
      ...card,
      userid: auth._id
    }));
    cardData.profilePhoto &&
      formData.append("perfilImage", cardData.profilePhoto);
    cardData.coverPhoto && formData.append("coverImage", cardData.coverPhoto);
    cardData.logo && formData.append("logoImage", cardData.logo);

    // * Mandamos los Url para saber si están en default o no
    formData.append("perfilImageUrl", cardData.profilePhotoUrl);
    formData.append("coverImageUrl", cardData.coverPhotoUrl);
    formData.append("logoImageUrl", cardData.logoUrl);

    formData.append("userid", auth._id);
    console.log('user id', auth._id);
    const res = await createCard(formData);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      setIsCreating(false);
      actualizarUser();
      resetAll(e);
      setFinishing(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let card = {
      ...cardData,
      name: auth.name,
      email: auth.email,
    };
    if (card.socialMedia && !card.socialMedia[0]) delete card.socialMedia;
    const formData = new FormData();
    formData.append("data", JSON.stringify({
      ...card,
      userid: auth._id
    }));
    cardData.profilePhoto &&
      formData.append("perfilImage", cardData.profilePhoto);
    cardData.coverPhoto && formData.append("coverImage", cardData.coverPhoto);
    cardData.logo && formData.append("logoImage", cardData.logo);
    formData.append("userid", auth._id);
    console.log('user id', auth._id);

    const res = await updateCard(cardData._id, formData);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      setIsCreating(false);
      actualizarUser();
      resetAll(enqueueSnackbar);
      setFinishing(false);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
    setIsLoading(false);
  };
  if (isLoading)
    return (
      <motion.div id="finish_modal" animate={{ transform: "scale(1)" }}>
        <form action="POST">
          <h2>Guardando tarjeta...</h2>

          <TailSpin className="loading" />
        </form>
      </motion.div>
    );

  if (!isLoading)
    return (
      <motion.div id="finish_modal" animate={{ transform: "scale(1)" }}>
        <form action="POST">
          <h2>¿Está seguro que quiere guardar su tarjeta?</h2>

          <div className="form_buttons">
            <button
              onClick={(e) => (isEditing ? handleEdit(e) : handleSend(e))}
              className="btn btn--save"
            >
              Sí
            </button>
            <button
              className="btn btn--cancel"
              onClick={(e) => setFinishing(false)}
            >
              No
            </button>
          </div>

          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => setFinishing(false)}
            className="close_modal"
          />
        </form>
      </motion.div>
    );
};

export default FinishModal;
