import React, { useState } from "react";
import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import {
  passwordRecovery,
  sendNewPassword,
  verifyRecoveryCode,
} from "../api/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import routes from "../router/routes";
import FirstStep from "../components/passwordRecovery/FirstStep";
import SecondStep from "../components/passwordRecovery/SecondStep";
import ThirdStep from "../components/passwordRecovery/ThirdStep";
import LoadingModal from "../components/common/LoadingModal"

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [next, setNext] = useState(false);
  const [finish, setFinish] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    const res = await passwordRecovery(email);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      setUserId(res.data.userId);
      setNext(true);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
    setIsLoading(false)
  };

  const handleSubmitCode = async () => {
    setIsLoading(true)
    const res = await verifyRecoveryCode(email, code);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      setFinish(true);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
    setIsLoading(false)
  };

  const handleChangePassword = async () => {
    setIsLoading(true)
    if (password !== confirmPassword) {
      enqueueSnackbar("Los campos no coinciden", { variant: "error" });
    } else {
      const res = await sendNewPassword(userId, password);
      if (res.status) {
        enqueueSnackbar(res.message, { variant: "success" });
        navigate(routes.login);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    }
    setIsLoading(false)
  };
  return (
    <>
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item xs={10} md={4}>
        <Card component={Paper} elevation={3} sx={{ minHeight: 350 }}>
          <CardContent sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography variant="h5" sx={{ fontWeight: "400", mt: 3 }}>
              Recuperar contraseña
            </Typography>
            {!finish ? (
              !next ? (
                <FirstStep
                  email={email}
                  setEmail={setEmail}
                  handleSubmit={handleSubmit}
                />
              ) : (
                <SecondStep
                  code={code}
                  setCode={setCode}
                  handleSubmitCode={handleSubmitCode}
                />
              )
            ) : (
              <ThirdStep
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                handleChangePassword={handleChangePassword}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    {isLoading && <LoadingModal />}
    </>
  );
};

export default ChangePassword;
