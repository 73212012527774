import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkUser } from "../api/auth";
import { authLogin, authLogout } from "../redux/actions/auth.actions";
import routes from "./routes";
import EnterpriseRoutes from "./EnterpriseRoutes";
import UserRoute from "./UserRoute";
import Membership from "../pages/Membership";
import LayoutRenderer from "../globals/LayoutRenderer";
import Login from "../pages/Login";
import Register from "../pages/Register";
import BiznesCardsPage from "../pages/BiznesCardsPage";
import Enterprise from "../pages/Enterprise";
import EditEnterprise from "../components/enterprise/EditEnterprise";
import NewUsers from "../components/enterprise/NewUsers";
import Users from "../components/enterprise/Users";
import Cards from "../components/enterprise/Cards";
import RegisterByInvitation from "../pages/RegisterByInvitation";
import UserProfile from "../components/enterprise/users/UserProfile";
import NewRole from "../components/enterprise/users/NewRole";
import RoleList from "../components/enterprise/users/RoleList";
import CardTemplatePreview from "../pages/CardTemplatePreview";
import CheckSuscription from "../pages/CheckSuscription";
import Home from "../pages/Home";
import EditRole from "../components/enterprise/users/EditRole";
import UserConfig from "../pages/UserConfig";
import PublicRoute from "./PublicRoute";
import ChangePassword from "../pages/ChangePassword";
import CardPreview from "../pages/CardPreview";
import AgendaBiznes from "../pages/AgendaBiznes";
import AgendaUserProfile from "../components/agendaBiznes/AgendaUserProfile";
import MyContacts from "../pages/MyContacts";
import Stats from "../pages/Stats";
import StatsCard from "../pages/StatsCard";
import CardsAdmin from "../pages/CardsAdmin";

const AppRouter = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  const identifyUser = async () => {
    const token = localStorage.getItem("token");

    if (!token) return setIsAuthenticated(true);

    const res = await checkUser();
    if (res && res.status) {
      dispatch(
        authLogin({
          ...res.data.userToSend,
          userType: res.data.userType,
          token,
        })
      );
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem("token");
      dispatch(authLogout());
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    identifyUser();
  }, []);

  return (
    <>
      {isAuthenticated && (
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path={routes.template}
              element={<CardTemplatePreview />}
            />
            <Route exact path={routes.card} element={<CardPreview />} />
            <Route element={<LayoutRenderer />}>
              {/* RUTAS SIN SESIÓN */}
              <Route element={<PublicRoute />}>
                <Route exact path={routes.login} element={<Login />} />
                <Route exact path={routes.register} element={<Register />} />
                <Route
                  exact
                  path={routes.passwordRecovery}
                  element={<ChangePassword />}
                />
              </Route>
              <Route
                exact
                path={routes.registerByInvitation}
                element={<RegisterByInvitation />}
              />
              <Route
                exact
                path={routes.checkSuscription}
                element={<CheckSuscription />}
              />

              <Route path={routes.stats} element={<Stats />} />
              <Route path={
                `${routes.stats}/:id`
              } element={<StatsCard />} />

              {/* RUTAS CON SESIÓN EMPRESA*/}
              <Route element={<EnterpriseRoutes />}>
                <Route
                  exact
                  path={routes.membership}
                  element={<Membership />}
                />
                <Route
                  exact
                  path={routes.biznesCards}
                  element={<BiznesCardsPage />}
                />
                <Route
                  exact
                  path={routes.adminCards}
                  element={<CardsAdmin />}
                />
                <Route path={routes.enterprise} element={<Enterprise />}>
                  {/* RUTAS DENTRO DE ENTERPRISE */}
                  <Route
                    path={routes.enterpriseEdit}
                    element={<EditEnterprise />}
                  />
                  <Route path={routes.enterpriseCards} element={<Cards />} />
                  <Route path={routes.enterpriseUsers} element={<Users />} />
                  <Route
                    path={routes.enterpriseNewUsers}
                    element={<NewUsers />}
                  />
                  <Route
                    path={routes.enterpriseUserProfile}
                    element={<UserProfile />}
                  />
                  <Route
                    path={routes.enterpriseCreateRole}
                    element={<NewRole />}
                  />
                  <Route
                    path={routes.enterpriseRoleList}
                    element={<RoleList />}
                  />
                  <Route
                    path={routes.enterpriseEditRole}
                    element={<EditRole />}
                  />
                </Route>
              </Route>

              {/* RUTAS CON SESIÓN USUARIO*/}
              <Route element={<UserRoute />}>
                <Route path={routes.home} element={<Home />} />
                <Route path={routes.agendaBiznes} element={<AgendaBiznes />} />
                <Route
                  path={routes.agendaProfile}
                  element={<AgendaUserProfile />}
                />
                <Route path={routes.myContacts} element={<MyContacts />} />
                <Route path={routes.myContactsProfile} element={<AgendaUserProfile />} />
                <Route path={routes.userConfig} element={<UserConfig />} />
                <Route path={routes.stats} element={<Stats />} />
                <Route path={
                  `${routes.stats}/:id`
                } element={<StatsCard />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};

export default AppRouter;
