import React, { useEffect, useState } from 'react'
import styles from '../../pages/StatsCard.module.scss'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Device({ card, filters, authorized }) {
    const [views, setViews] = useState([])
    const [data, setData] = useState({
        labels: ['Escritorio', 'Dispositivos móviles'],
        datasets: [],
    })

    const options = {
        responsive: true,
    }

    useEffect(() => {
        if (filters.from && filters.to) {
            if (filters.from !== filters.to) {
                const filteredViews = card.views.filter(view => {
                    return view.date.split('T')[0] >= filters.from && view.date.split('T')[0] <= filters.to
                })
                setViews(filteredViews)
            } else {
                const filteredViews = card.views.filter(view => {
                    return view.date.split('T')[0] === filters.from
                })
                setViews(filteredViews)
            }
        }
    }, [filters])

    useEffect(() => {
        setData({
            labels: ['PC', 'Dispositivos móviles'],
            datasets: [
                {
                    label: "% de vistas por dispositivo",
                    data: authorized ? [views.filter(view => view.device === 'pc').length, views.filter(view => view.device === 'mobile').length] : [0, 0],
                    backgroundColor: [
                        '#7577CD',
                        '#63B5E5',
                    ],
                }
            ]
        })
    }, [views])

    return (
        <div className={styles.stat} id={styles.device} authorized={
            authorized ? 'true' : 'false'
        }>
            <h3>
                ¿Desde qué dispositivo se han visto tus tarjetas?
            </h3>
            <Pie data={data} options={options} />
        </div>
    )
}
