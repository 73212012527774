import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getUserById, getUserByIdNetworking } from "../../api/user";
import AgendaUserProfileCard from "./AgendaUserProfileCard";

const AgendaUserProfile = () => {
  const [profile, setProfile] = useState(null)
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const userType = searchParams.get("user_type");
  const getData = async () => {
    if (userType === "enterprise") {
      const res = await getUserById(id)
      if (res.status) {
        setProfile(res.data)
      }
    } else {
      const res = await getUserByIdNetworking(id)
      if (res){
        setProfile(res)
      }
    }
  }
  useEffect(() => {
    getData()
  }, [])
  console.log(profile)
  if (profile) return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{profile.firstName} {profile.lastName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{profile.description && profile.description}</Typography>
      </Grid>
      {profile.cards && profile.cards[0] && profile.cards.map((card)=>(
        <Grid item xs={12} md={4} xl={3}>
          <AgendaUserProfileCard template={card} userType={userType}/>
        </Grid>
      ))}
      
    </Grid>
  )
};

export default AgendaUserProfile;
