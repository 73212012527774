import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRoles } from "../../../api/enterprise";

const RoleList = () => {
  const [rows, setRows] = useState([]);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    getData();
    return () => {
      setRows([]);
    };
  }, []);

  const getData = async () => {
    const res = await getRoles(auth._id);
    if (res.status) {
      setRows([]);
      if (res.data && res.data[0]) {
        res.data.map((rol) => {
          setRows((data) => [
            ...data,
            createData(rol.name, rol.color, rol._id),
          ]);
        });
      }
    }
  };

  const createData = (name, color, _id) => {
    return { name, color, _id };
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Color</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ backgroundColor: row.color || "white" }}>
                    {row.color}
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`/enterprise/edit_role/${row._id}`}>
                      <button className="main_button">Editar</button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default RoleList;
