import React, { useContext, useState } from "react";
import CardDataContext from "./CardData.provider";
import "./CardModalFormData.scss";
import { useSelector } from "react-redux";
import CheckIfMembership from "./CheckIfMembership";
import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material";

const CardModalFormData = ({ roles }) => {
  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    handleRole(value);
  };
  const {
    cardData,
    handleInputs,
    handleRole,
    handleImages,
    handleBoolean,
    handleAdditionalButton,
    handleEditAdditionalButton,
    handleDeleteAdditionalButton,
  } = useContext(CardDataContext);
  const auth = useSelector((state) => state.auth);

  const descriptionLimit = () => {
    const permission = auth.membership.permissions.find(
      (permission) => permission.permission === "Descripción"
    );

    return permission.limit;
  };

  const additionalButtonsLimit = () => {
    const permission = auth.membership.permissions.find(
      (permission) => permission.permission === "Botones adicionales"
    );

    return permission.limit;
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  return (
    <form className="card_modal_form_content" id="card_modal_form_data">
      <h2>PLANTILLA DE TARJETA EMPRESARIAL</h2>

      <div className="form_section images">
        <h3>ROLES PERMITIDOS</h3>
        <Select
          multiple
          value={cardData.roles}
          sx={{
            width: "80%",
            mt: 3,
            ml: 1,
          }}
          onChange={handleChangeSelect}
          renderValue={(selected) => {
            let select = [];
            selected &&
              selected.map((item) => {
                let data = roles.find((role) => role._id === item);
                select.push(`${data.name}, `);
              });
            return select;
          }}
          MenuProps={MenuProps}
        >
          {roles &&
            roles[0] &&
            roles.map((role) => (
              <MenuItem key={role._id} value={role._id}>
                <Checkbox checked={cardData.roles.indexOf(role._id) > -1} />
                <ListItemText primary={role.name} />
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className="form_section images">
        <h3>Imagenes</h3>

        <div className="form_group">
          <label htmlFor="">Imágen de portada (opcional)</label>

          <CheckIfMembership value="Foto de portada">
            <>
              <label htmlFor="coverPhoto" className="pick_image_label">
                Seleccionar archivo
              </label>
              {cardData.coverPhoto && (
                <p style={{ textAlign: "center", color: "green" }}>
                  Imágen seleccionada
                </p>
              )}
              <input
                type="file"
                style={{ cursor: "pointer" }}
                name="coverPhoto"
                id="coverPhoto"
                onChange={(e) => handleImages(e)}
              />
            </>
          </CheckIfMembership>
        </div>

        <div className="form_group">
          <label htmlFor="">Imágen de perfil (opcional)</label>

          <label htmlFor="profilePhoto" className="pick_image_label">
            Seleccionar archivo
          </label>
          {cardData.profilePhoto && (
            <p style={{ textAlign: "center", color: "green" }}>
              Imágen seleccionada
            </p>
          )}
          <input
            type="file"
            style={{ cursor: "pointer" }}
            name="profilePhoto"
            id="profilePhoto"
            onChange={(e) => handleImages(e)}
          />
        </div>

        <div className="form_group">
          <label htmlFor="logo">Logo de empresa (opcional)</label>

          <CheckIfMembership value="Foto de empresa">
            <>
              <label htmlFor="logo" className="pick_image_label">
                Seleccionar archivo
              </label>
              {cardData.logo && (
                <p style={{ textAlign: "center", color: "green" }}>
                  Imágen seleccionada
                </p>
              )}
              <input
                type="file"
                style={{ cursor: "pointer" }}
                name="logo"
                id="logo"
                onChange={(e) => handleImages(e)}
              />
            </>
          </CheckIfMembership>
        </div>
      </div>

      <div className="form_section">
        <h3>Información</h3>

        <div className="form_group">
          <label htmlFor="jobPosition">Puesto</label>
          <input
            type="text"
            name="jobPosition"
            id="jobPosition"
            value={cardData.jobPosition}
            onChange={(e) => handleInputs(e)}
          />
        </div>

        <div className="form_group">
          <label htmlFor="jobEntity">Empresa</label>
          <input
            type="text"
            name="jobEntity"
            id="jobEntity"
            disabled
            value={auth.name}
            onChange={(e) => handleInputs(e)}
          />
        </div>

        <div className="form_group full">
          <label htmlFor="cellphone">Número de teléfono</label>

          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontSize: 18, marginRight: 15 }}>+56</p>
            <input
              type="number"
              name="cellphone"
              id="cellphone"
              value={cardData.cellphone}
              onChange={(e) => handleInputs(e)}
              defaultValue="+"
            />
          </div>
        </div>

        <div className="form_group full">
          <label htmlFor="biography">Descríbete en 150 carácteres</label>
          <textarea
            name="biography"
            id="biography"
            value={cardData.biography}
            onChange={(e) => handleInputs(e)}
            maxLength={descriptionLimit()}
          />
        </div>
      </div>

      {/* <div className="form_group full">
        <label htmlFor="cardLink">
          ¿Cómo quieres que encuentren tu tarjeta?
          <br />
          (en caso de llenar este campo, le asignaremos un valor aleatorio)
        </label>

        <div
          className="cardlink_input"
          style={{ display: "flex", alignItems: "center", marginTop: 15 }}
        >
          <p style={{ fontSize: 20, paddingLeft: 10 }}>
            https://empresas.biznes.cl/
          </p>

          <input
            type="text"
            name="cardLink"
            id="cardLink"
            style={{
              backgroundColor: "white",
              padding: "8px 5px",
            }}
            value={cardData.cardLink}
            onChange={(e) => handleInputs(e)}
          />
        </div>
      </div> */}

      <div className="form_section">
        <div className="form_group full vcard">
          <h3>Habilitar descarga de tarjeta virtual</h3>

          <div className="" style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                marginBottom: 0,
                padding: "10px 30px",
                background: "var(--color-logo)",
                border: "1px solid black",
                color: "black",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleBoolean("vcardWants");
              }}
            >
              {cardData.vcardWants ? "Deshabilitar" : "Habilitar"}
            </button>
          </div>

          <p className="disclaimer" style={{ marginTop: 20 }}>
            El formato .vcf (también conocido como V Card o Virtual Card) es un
            formato estándar para el intercambio de información personal,
            específicamente tarjetas personales electrónicas.
          </p>

          <p className="disclaimer">
            La información que tenga en la tarjeta Biznes Card se guardará, a su
            vez, en un archivo .vcf que podrá descargar y compartir con otras
            personas.
          </p>
        </div>
      </div>

      <div className="form_section">
        <div className="form_group full vcard">
          <h3>Habilitar botón de llamar</h3>

          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                marginBottom: 0,
                padding: "10px 30px",
                background: "var(--color-logo)",
                border: "1px solid black",
                color: "black",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleBoolean("addContact");
              }}
            >
              {cardData.addContact ? "Deshabilitar" : "Habilitar"}
            </button>
          </div>

          <p className="disclaimer" style={{ marginTop: 20 }}>
            Al habilitar esta opción, los usuarios podrán añadir su tarjeta a la
            lista de contactos de su dispositivo móvil.
          </p>
        </div>
      </div>

      <div className="form_section">
        <div className="form_group full vcard">
          <h3>Agregar botones adicionales</h3>

          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                marginBottom: 0,
                padding: "10px 30px",
                background: "var(--color-logo)",
                border: "1px solid black",
                color: "black",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (cardData.additionalButtons) {
                  handleAdditionalButton();
                }
              }}
            >
              Agregar botón adicional
            </button>
          </div>

          {cardData.additionalButtons &&
            cardData.additionalButtons[0] &&
            cardData.additionalButtons.map((button, index) => (
              <div className="form_section" style={{ marginTop: 30 }}>
                <h4>Botón #{index + 1}</h4>
                <button
                  className="delete_button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteAdditionalButton(index);
                  }}
                >
                  Eliminar botón
                </button>
                <div className="form_group full">
                  <label htmlFor="text">Texto</label>
                  <input
                    type="text"
                    name="text"
                    id="text"
                    value={cardData.additionalButtons[index].text}
                    onChange={(e) => handleEditAdditionalButton(e, index)}
                  />
                </div>

                <div className="form_group full">
                  <label htmlFor="link">Link</label>
                  <input
                    type="text"
                    name="link"
                    id="link"
                    value={cardData.additionalButtons[index].link}
                    onChange={(e) => handleEditAdditionalButton(e, index)}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </form>
  );
};

export default CardModalFormData;
