import React, { useEffect, useState } from 'react'
import styles from '../../pages/StatsCard.module.scss'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function Views({ card, filters, authorized }) {
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })
    const [socialMedias, setSocialMedias] = useState({})

    const options = {
        plugins: {
            legend: false
        },
        responsive: true,
    }

    useEffect(() => {
        if (filters.from && filters.to) {
            const filteredViews = card.clicksSocialMedia.filter(social => {
                return social.date.split('T')[0] >= filters.from && social.date.split('T')[0] <= filters.to
            })

            const qtyPerDay = {}
            filteredViews.forEach(social => {
                if(social.socialMedia) {
                    if (qtyPerDay[social.socialMedia]) {
                        qtyPerDay[social.socialMedia]++
                    } else {
                        qtyPerDay[social.socialMedia] = 1
                    }

                }
            })

            setSocialMedias(qtyPerDay)
        }
    }, [filters])

    useEffect(() => {
        setData({
            labels: Object.keys(socialMedias),
            datasets: [
                {
                    label: "Clicks",
                    data: authorized ? Object.values(socialMedias) : [0, 0],
                    backgroundColor: '#7577CD',
                }
            ]
        })
    }, [socialMedias])

    return (
        <div className={styles.stat} id={styles.views} authorized={
            authorized ? 'true' : 'false'
        }>
            <h3>Clicks en redes sociales</h3>
            <Bar data={data} options={options} />
        </div>
    )
}
