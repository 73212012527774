import React, { useEffect, useState } from 'react'
import styles from '../../pages/StatsCard.module.scss'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function Views({ card, filters, labels, authorized }) {
    const [views, setViews] = useState([])
    const [data, setData] = useState({
        labels: filters.from === filters.to ? Object.keys(views) : labels,
        datasets: [],
    })

    const options = {
        plugins: {
            legend: false
        },
        responsive: true,
    }

    useEffect(() => {
        if (filters.from && filters.to) {
            if (filters.from !== filters.to) {
                const filteredViews = card.views.filter(view => {
                    return view.date.split('T')[0] >= filters.from && view.date.split('T')[0] <= filters.to
                })

                const qtyPerDay = {}
                filteredViews.forEach(view => {
                    const date = view.date.split('T')[0].replace(/-/g, '/')

                    if (qtyPerDay[date]) {
                        qtyPerDay[date]++
                        qtyPerDay[`${date}_time`] += view.time
                    } else {
                        qtyPerDay[date] = 1
                        qtyPerDay[`${date}_time`] = view.time
                    }
                })
                setViews(qtyPerDay)
            } else {
                const filteredViews = card.views.filter(view => {
                    return view.date.split('T')[0] === filters.from
                })

                const qtyPerHour = {}

                filteredViews.forEach(view => {
                    const date = new Date(view.date)
                    const hour = `${date.getHours()}:00 hs`
                    if (qtyPerHour[hour]) {
                        qtyPerHour[hour]++
                        qtyPerHour[`${hour}_time`] += view.time
                    } else {
                        qtyPerHour[hour] = 1
                        qtyPerHour[`${hour}_time`] = view.time
                    }
                })
                setViews(qtyPerHour)
            }
        }
    }, [filters])

    useEffect(() => {
        setData({
            labels: filters.from === filters.to ? Object.keys(views) : labels,
            datasets: [
                {
                    label: "Tiempo promedio",
                    data: authorized ? filters.from === filters.to ? Object.values(views) : Array(labels.length).fill(0).map((_, i) => {
                        if(views[labels[i]]) {
                            const timeInSec = views[`${labels[i]}_time`] / views[labels[i]]
                            
                            return parseInt(timeInSec)
                        } else {
                            return 0
                        }
                    }, []) : [],
                    backgroundColor: '#7577CD',
                }
            ]
        })
    }, [views])

    return (
        <div className={styles.stat} id={styles.views} authorized={
            authorized ? 'true' : 'false'
        }>
            <h3>Tiempo promedio visto (segundos)</h3>
            <Bar data={data} options={options} />
        </div>
    )
}
