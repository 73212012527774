import backendApi from "./config";

const getMemberships = async () => {
  try {
    const { data } = await backendApi.get(`/membership/`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getSuscriptionPaymentLink = async (membershipId, frecuencyPayment) => {
  try {
    const { data } = await backendApi.post(`/membershipPayment`, {
      membershipId,
      frecuencyPayment,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const createMemberships = async (id) => {
  try {
    const { data } = await backendApi.post(
      `/membershipPayment/check-suscription`,
      {
        preapproval_id: id,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const cancelMembershipSuscription = async () => {
  try {
    const { data } = await backendApi.put(
      `/membershipPayment/cancel-suscription`
    );
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export {
  getMemberships,
  getSuscriptionPaymentLink,
  createMemberships,
  cancelMembershipSuscription,
};
