export default {
  login: "/login",
  register: "/register",
  passwordRecovery: "/recover_password",
  registerByInvitation: "/register_invitation/:id",
  template: "/card_template/:id",
  card: "/:id",
  //USER
  home: "/",
  userConfig: "/user_configuration",
  agendaBiznes: "/agenda_biznes",
  agendaProfile: "/agenda_biznes/user_profile/:id",
  myContacts: "/my_contacts",
  myContactsProfile: "/my_contacts/user_profile/:id", 
  stats: "/stats",
  adminCards: "/admin_cards",
  //ENTERPRISE
  biznesCards: "/biznes_cards",
  enterprise: "/enterprise",
  enterpriseEdit: "/enterprise/edit",
  enterpriseCards: "/enterprise/cards",
  enterpriseUsers: "/enterprise/users",
  enterpriseUserProfile: "/enterprise/user_profile/:id",
  enterpriseNewUsers: "/enterprise/new_users",
  enterpriseCreateRole: "/enterprise/create_role",
  enterpriseRoleList: "/enterprise/role_list",
  enterpriseEditRole: "/enterprise/edit_role/:id",
  checkSuscription: "/check_suscription",
  membership: "/membership",
  statsEnterprise: "/stats_enterprise",
};
