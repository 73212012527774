import React from "react";
import { motion } from "framer-motion";
import { TailSpin } from "react-loader-spinner";

const LoadingModal = ({ msg }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.div id="finish_modal" animate={{ transform: "scale(1)" }}>
        <form action="POST">
          <h2>{msg ? msg : "Cargando..."}</h2>

          <TailSpin className="loading" />
        </form>
      </motion.div>
    </div>
  );
};

export default LoadingModal;
