import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "./routes";

const PublicRoute = ({ rest }) => {
  const auth = useSelector((state) => state.auth);
  let location = useLocation();

  if (auth.logged && auth.userType === "user") {
    return <Navigate to={routes.home} state={{ from: location }} replace />;
  } else if (auth.userType === "enterprise") {
    return (
      <Navigate to={routes.enterprise} state={{ from: location }} replace />
    );
  }
  return <Outlet {...rest} />;
};

export default PublicRoute;
