import {
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { register } from "../api/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import routes from "../router/routes";
import logo from "../assets/logoSmall.png";
import LoadingModal from "../components/common/LoadingModal";

const Register = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    description: "",
    email: "",
    password: "",
    logo: null,
    coverImage: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const updateForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateImagesForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("description", form.description);
    formData.append("email", form.email);
    formData.append("password", form.password);
    form.logo && formData.append("logo", form.logo);
    form.coverImage && formData.append("coverImage", form.coverImage);
    const res = await register(formData);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      setTimeout(() => {
        navigate(routes.login);
      }, 1000);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Card
            component={Paper}
            elevation={5}
            sx={{ minHeight: 500, maxWidth: "650px" }}
          >
            <CardContent sx={{ justifyContent: "center", textAlign: "center" }}>
              <img src={logo} width={100} />

              <Grid
                container
                sx={{ py: 5, textAlign: "left", justifyContent: "center" }}
              >
                <Grid item xs={12} md={10}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Nombre de la empresa
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="name"
                    value={form.name}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Descripción
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="description"
                    value={form.description}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="email"
                    value={form.email}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Contraseña
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="password"
                    value={form.password}
                    fullWidth
                    type="password"
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Logo
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="logo"
                    fullWidth
                    type="file"
                    onChange={(evt) => updateImagesForm(evt)}
                  />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Foto de portada
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="coverImage"
                    fullWidth
                    type="file"
                    onChange={(evt) => updateImagesForm(evt)}
                  />
                </Grid>

                <Grid item xs={12} md={10} sx={{ py: 3, textAlign: "center" }}>
                  <button
                    className="main_button"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    Registrarse
                  </button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isLoading && <LoadingModal />}
    </>
  );
};

export default Register;
