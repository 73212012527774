import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "../../redux/actions/auth.actions";
import routes from "../../router/routes";
import logo from "../../assets/logoSmall.png";

const Navbar = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuToggle, setMenuToggle] = useState(false);

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(authLogout());
    navigate(routes.login);
  };

  return (
    <div>
      <nav id="navbar">
        <div className="nav-wrapper">
          <div className="logo" style={{ padding: 1 }}>
            <Link to={!auth.logged ? routes.login : routes.home}>
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "40%",
                  objectFit: "cover",
                  transform: "scale(3)",
                  backgroundColor: "white",
                }}
              />
            </Link>
          </div>

          <ul id="menu">
            {!auth.logged && (
              <li>
                <Link to={routes.login}>Iniciar sesión</Link>
              </li>
            )}
            {!auth.logged && (
              <li>
                <Link to={routes.register}>Registrarse</Link>
              </li>
            )}

            {auth.logged && (
              <li>
                <Link onClick={() => logout()}>Cerrar sesión</Link>
              </li>
            )}
          </ul>
        </div>
      </nav>

      <div className="menuIcon" onClick={() => setMenuToggle(!menuToggle)}>
        <span className="icon icon-bars"></span>
        <span className="icon icon-bars overlay"></span>
      </div>

      <div className={menuToggle ? "overlay-menu active" : "overlay-menu"}>
        <FontAwesomeIcon
          icon={faXmark}
          onClick={() => setMenuToggle(false)}
          id="close-overlay-menu"
        />

        <ul id="menu">
          {!auth.logged && (
            <>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.login}>Iniciar sesión</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.register}>Registrarse</Link>
              </li>
            </>
          )}

          {auth.logged && auth.userType === "enterprise" && (
            <>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.enterprise}>Biznes empresas</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.biznesCards}>Tarjetas Biznes</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.membership}>Membresías</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link onClick={() => logout()}>Cerrar sesión</Link>
              </li>
            </>
          )}

          {auth.logged && auth.userType === "user" && (
            <>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.home}>Inicio</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.agendaBiznes}>Agenda Biznes</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.myContacts}>Mis Contactos</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.userConfig}>Configuración de usuario</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link to={routes.stats}>Estadísticas</Link>
              </li>
              <li onClick={() => setMenuToggle(false)}>
                <Link onClick={() => logout()}>Cerrar sesión</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
