import React from 'react'
import { Grid, Typography } from '@mui/material'
import Cards from '../components/userHome/Cards'
import routes from '../router/routes'
import { useSearchParams } from "react-router-dom";

export default function Stats() {
  const [searchParams] = useSearchParams();
  const params = searchParams.get("type");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Seleccione una tarjeta</Typography>
      </Grid>

      <Grid item xs={12}>
        <Cards redirectTo={routes.stats} buttonText={'Ver estadísticas'} cardsFromEnterprise={
          params === 'enterprise' ? true : false
        } />
      </Grid>
    </Grid>
  )
}
