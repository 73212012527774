import { Grid, Typography } from "@mui/material";
import React from "react";
import Cards from "../components/userHome/Cards";
import Templates from "../components/userHome/Templates";

const Home = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Mis tarjetas</Typography>
      </Grid>
      <Grid item xs={12}>
        <Cards />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Plantillas</Typography>
      </Grid>
      <Grid item xs={12}>
        <Templates />
      </Grid>
    </Grid>
  );
};

export default Home;
