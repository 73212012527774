import React, { useEffect, useState } from 'react'
import styles from '../../pages/StatsCard.module.scss'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function ButtonsClicks({ card, filters, authorized }) {
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })
    const [additionalButtons, setAdditionalButtons] = useState({})

    const options = {
        plugins: {
            legend: false
        },
        responsive: true,
    }

    useEffect(() => {
        if (filters.from && filters.to) {
            const aux = {}

            card.additionalButtons.forEach(button => {
                const filtered = button.clicks.filter(social => {
                    return social.date.split('T')[0] >= filters.from && social.date.split('T')[0] <= filters.to
                })

                const qty = filtered.length

                aux[button.text] = qty
            })

            setAdditionalButtons(aux)
        }
    }, [filters])

    useEffect(() => {
        setData({
            labels: Object.keys(additionalButtons),
            datasets: [
                {
                    label: "Clicks",
                    data: authorized ? Object.values(additionalButtons) : [],
                    backgroundColor: '#7577CD',
                }
            ]
        })
    }, [additionalButtons])

    return (
        <div className={styles.stat} id={styles.views} authorized={
            authorized ? 'true' : 'false'
        }>
            <h3>Clicks en botones adicionales</h3>
            <Bar data={data} options={options} />
        </div>
    )
}
