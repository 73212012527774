import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { createNewRole } from "../../../api/enterprise";
import { useNavigate } from "react-router-dom";
import routes from "../../../router/routes";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import LoadingModal from "../../common/LoadingModal";

const NewRole = () => {
  const [members, setMembers] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    name: "",
    color: "#ffffff",
    permissions: [],
    users: [],
  });

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setForm((prevForm) => ({
      ...prevForm,
      users: typeof value === "string" ? value.split(",") : value,
    }));
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (auth && auth.members && auth.members[0]) setMembers(auth.members);
  }, []);

  const handleChange = (newColor) => {
    setForm((prevForm) => ({
      ...prevForm,
      color: newColor,
    }));
  };

  const updateForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updatePermissions = (event, value) => {
    if (event.target.checked) {
      setForm({ ...form, permissions: [...form.permissions, value] });
    } else
      setForm((prevForm) => ({
        ...prevForm,
        permissions: prevForm.permissions.filter(
          (permission) => permission !== value
        ),
      }));
  };

  const sendForm = async () => {
    const res = await createNewRole(form);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      navigate(routes.enterpriseUsers);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const permissions = [
    { name: "Editar nombre", value: "editName" },
    { name: "Editar imagen de perfil", value: "editUserImage" },
    { name: "Editar logo de empresa", value: "editLogo" },
    { name: "Editar imagen de portada", value: "editBannerImage" },
    { name: "Editar número de teléfono", value: "editPhoneNumber" },
    { name: "Editar descripción", value: "editDescription" },
    { name: "Editar url de tarjeta", value: "editUrl" },
    { name: "Editar opción de descarga virtual", value: "editDownload" },
    { name: "Editar opción de llamar", value: "editCall" },
    { name: "Agregar botones adicionales", value: "editAdditionalButtons" },
    { name: "Editar redes sociales", value: "editSocialMedia" },
    { name: "Editar diseño", value: "editDesign" },
  ];
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  return (
    <>
      <motion.div
        className="user_card"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        animate={{ transform: "scale(1)" }}
        transition={{ duration: 0.45 }}
      >
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item xs={10}>
            <Card component={Paper} elevation={5}>
              <CardContent>
                <Grid container spacing={3} sx={{ p: 3 }}>
                  <Grid item xs={12}>
                    <Typography>Puesto</Typography>
                    <TextField
                      variant="standard"
                      name="name"
                      value={form.name}
                      sx={{ my: 2 }}
                      fullWidth
                      onChange={(evt) => updateForm(evt)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Color del rol</Typography>
                    <MuiColorInput
                      value={form.color}
                      onChange={handleChange}
                      format="hex"
                      sx={{ my: 2 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Permisos:</Typography>
                  </Grid>
                  {permissions.map((item, index) => (
                    <Grid item xs={4} sx={{ textAlign: "center" }} key={index}>
                      <Typography>{item.name}</Typography>
                      <Checkbox
                        onChange={(event) =>
                          updatePermissions(event, item.value)
                        }
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Typography>Usuarios:</Typography>
                    <Select
                      multiple
                      value={personName}
                      sx={{ minWidth: "100%", mt: 3 }}
                      onChange={handleChangeSelect}
                      renderValue={(selected) => {
                        let select = [];
                        selected &&
                          selected.map((item) => {
                            let data = auth.members.find(
                              (user) => user._id === item
                            );
                            select.push(`${data.firstName} ${data.lastName}, `);
                          });
                        return select;
                      }}
                      MenuProps={MenuProps}
                    >
                      {members &&
                        members[0] &&
                        members.map((user) => (
                          <MenuItem key={user._id} value={user._id}>
                            <Checkbox
                              checked={personName.indexOf(user._id) > -1}
                            />
                            <ListItemText
                              primary={`${user.firstName} ${user.lastName}`}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                    <button className="main_button" onClick={() => sendForm()}>
                      Crear nuevo rol
                    </button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </motion.div>
      {isLoading && <LoadingModal msg="Creando nuevo rol..." />}
    </>
  );
};

export default NewRole;
