import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { getEnterpriseLastCard } from "../api/card";
import BiznesCard from "../components/biznesCards/BiznesCard";
import routes from "../router/routes";

const Enterprise = () => {
  const [cards, setCards] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const auth = useSelector((state) => state.auth);

  const getData = async () => {
    const res = await getEnterpriseLastCard(auth._id);
    if (res.status) {
      setCards(res.card);
    }
  };
  useEffect(() => {
    getData();
  }, [auth]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        {cards && cards[0] && (
          <Grid item xs={12}>
            <BiznesCard template={cards[0]} setIsEditing={setIsEditing} />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Link to={routes.enterpriseUsers}>
              <Button variant="contained" fullWidth sx={{ height: 215 }}>
                Usuarios y roles
              </Button>
            </Link>
          </Grid>

          <Grid item xs={6}>
            <Link to={routes.enterpriseNewUsers}>
              <Button variant="contained" fullWidth sx={{ height: 215 }}>
                Nuevos usuarios
              </Button>
            </Link>
          </Grid>

          <Grid item xs={6}>
            <Link to={routes.enterpriseCards}>
              <Button variant="contained" fullWidth sx={{ height: 215 }}>
                Mis Plantillas
              </Button>
            </Link>
          </Grid>

          <Grid item xs={6}>
            <Link to={routes.enterpriseEdit}>
              <Button variant="contained" fullWidth sx={{ height: 215 }}>
                Editar empresa
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 10 }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Enterprise;
