import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { createNewUser } from "../api/user";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import routes from "../router/routes";
import { useDispatch } from "react-redux";
import { authLogout } from "../redux/actions/auth.actions";

const RegisterByInvitation = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("invitated_mail");
  const rol = searchParams.get("rol_name");
  const rolId = searchParams.get("rol_id");
  const navigate = useNavigate();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: email,
    password: "",
    userImage: null,
  });

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(authLogout());
  };

  const updateForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateImagesForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async () => {
    if (form.firstName && form.lastName && form.email && form.password) {
      const formData = new FormData();
      formData.append("firstName", form.firstName);
      formData.append("lastName", form.lastName);
      formData.append("email", form.email);
      formData.append("password", form.password);
      rolId && formData.append("roles", [rolId]);
      form.userImage && formData.append("userImage", form.userImage);
      const res = await createNewUser(id, formData);
      if (res.status) {
        enqueueSnackbar(res.message, { variant: "success" });
        setTimeout(() => {
          navigate(routes.login);
        }, 1000);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar("Faltan completar campos", { variant: "error" });
    }
  };

  useEffect(() => {
    logout()
  }, [])
  
  return (
    <motion.div
      className="user_card"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={{ transform: "scale(1)" }}
      transition={{ duration: 0.45 }}
    >
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={10}>
          <Card component={Paper} elevation={5} sx={{ minHeight: 500 }}>
            <CardContent sx={{ justifyContent: "center", textAlign: "center" }}>
              <Grid container sx={{ py: 5, px: "20%", textAlign: "left" }}>
                {rol && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h5" sx={{ fontWeight: "500" }}>
                        Rol
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ py: 3 }}>
                      <TextField
                        variant="standard"
                        name="rol"
                        value={rol}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Nombre
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="firstName"
                    value={form.firstName}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Apellido
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="lastName"
                    value={form.lastName}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="email"
                    value={form.email}
                    fullWidth
                    disabled
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Contraseña
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="password"
                    value={form.password}
                    fullWidth
                    type="password"
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Foto de perfil
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="userImage"
                    fullWidth
                    type="file"
                    onChange={(evt) => updateImagesForm(evt)}
                  />
                </Grid>

                <Grid item xs={12} sx={{ py: 3, textAlign: "center" }}>
                  <button
                    className="main_button"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    Registrar nuevo usuario
                  </button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default RegisterByInvitation;
