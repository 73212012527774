import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import CardDataContext from "./CardData.provider";

const CheckIfMembership = ({ children, value }) => {
  const { role } = useContext(CardDataContext);

  const check = () => {
    const permission = role.permissions.some(
      (permission) => permission === value
    );

    return permission;
  };
  return (
    <>
      {check() ? (
        children
      ) : (
        <div className="block">
          <p>
            No tienes acceso a esta función. <FontAwesomeIcon icon={faLock} />
          </p>
        </div>
      )}
    </>
  );
};

export default CheckIfMembership;
