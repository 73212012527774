import { createContext, useState } from "react";
import defaultStyles from "./cardData.defaultStyles.js";
import { useSelector } from "react-redux";

export const CardDataContext = createContext();

const initialState = {
  socialMedia: [],
  name: "",
  email: "",
  biography: "",
  cellphone: "",
  vcardWants: false,
  additionalButtons: [],
  coverPhotoUrl: 'default',
  profilePhotoUrl: 'default',
  logoUrl: 'default',
};

const CardDataProvider = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const [cardData, setCardData] = useState({
    ...initialState,
    styles: defaultStyles,
    name: auth.name,
  });

  const handleInputs = (e) => {
    console.log(e.target.name, e.target.value);
    setCardData({ ...cardData, [e.target.name]: e.target.value });
  };

  const handleAdditionalButton = () => {
    const data = {
      visible: true,
      styles: {
        fontSize: 17,
        color: "white",
        letterSpacing: 0,
        backgroundColor: "black",
        borderRadius: 10,
        width: "80%",
        height: 60,
      },
    };
    setCardData({
      ...cardData,
      additionalButtons: [...cardData.additionalButtons, data],
    });
  };

  const handleEditAdditionalButton = (e, position) => {
    let card = { ...cardData };
    card.additionalButtons[position][e.target.name] = e.target.value;
    setCardData({ ...cardData, additionalButtons: card.additionalButtons });
  };

  const handleEditStylesAdditionalButton = (e, position) => {
    const isPercent = e.target.id.includes("percent");
    const isColor = e.target.id.includes("color");
    const isPixels = e.target.id.includes("pixels");
    const isText = e.target.id.includes("text");

    const card = JSON.parse(JSON.stringify(cardData));

    if (isPercent) {
      card.additionalButtons[position].styles[
        e.target.name
      ] = `${e.target.value}%`;
      setCardData({ ...cardData, additionalButtons: card.additionalButtons });
    } else if (isColor) {
      card.additionalButtons[position].styles[e.target.name] = e.target.value;
      setCardData({ ...cardData, additionalButtons: card.additionalButtons });
    } else if (isPixels) {
      card.additionalButtons[position].styles[
        e.target.name
      ] = `${e.target.value}px`;
      setCardData({ ...cardData, additionalButtons: card.additionalButtons });
    } else if (isText) {
      card.additionalButtons[position].styles[e.target.name] = e.target.value;
      setCardData({ ...cardData, additionalButtons: card.additionalButtons });
    }
  };

  const handleDeleteAdditionalButton = (position) => {
    const card = JSON.parse(JSON.stringify(cardData));
    card.additionalButtons.splice(position, 1);
    setCardData({ ...card });
  };

  const handleImages = (e) => {
    const fakeUrl = URL.createObjectURL(e.target.files[0]);
    console.log(fakeUrl);

    setCardData({
      ...cardData,
      [e.target.name]: e.target.files[0],
      [`${e.target.name}Url`]: fakeUrl,
    });
  };

  const deleteImage = (name) => {
    setCardData({ ...cardData, [name]: "", [`${name}Url`]: "" });
    console.log({ ...cardData, [name]: "", [`${name}Url`]: "" });
  };

  const handleBoolean = (property) => {
    if (cardData[property]) {
      setCardData({ ...cardData, [property]: false });
    } else {
      setCardData({ ...cardData, [property]: true });
    }
  };

  const handleSocialMedia = (e, icon) => {
    let aux = cardData.socialMedia;

    aux = aux.filter((item) => item.name !== icon.name);

    aux.push({
      name: icon.name,
      color: icon.color,
      contrast: icon.contrast,
      url: e.target.value,
      favorite: icon.favorite,
    });

    setCardData({ ...cardData, socialMedia: aux });
  };

  const handleRemoveSocial = (icon) => {
    const aux = cardData.socialMedia.filter((item) => item.name !== icon.name);

    setCardData({ ...cardData, socialMedia: aux });
  };

  const actualizeSocial = (socials) => {
    const auxSocial = [];

    socials.forEach((social) => {
      auxSocial.push({
        name: social.name,
        color: social.color,
        contrast: social.contrast,
        url: social.url,
        favorite: social.favorite,
      });
    });

    setCardData({ ...cardData, socialMedia: auxSocial });
  };

  const resetStyles = (e) => {
    e.preventDefault();

    setCardData({
      ...cardData,
      styles: defaultStyles,
    });

  };

  const resetSocialColors = (e) => {
    e.preventDefault();

    setCardData({
      ...cardData,
      styles: {
        ...cardData.styles,
        buttonSocialFavorite: {
          color: undefined,
          backgroundColor: undefined,
        },
        buttonSocial: {
          color: undefined,
          backgroundColor: undefined,
        },
      },
    });
  };

  const handleStyles = (e) => {
    const isPercent = e.target.id.includes("percent");
    const isColor = e.target.id.includes("color");
    const isPixels = e.target.id.includes("pixels");
    const isText = e.target.id.includes("text");

    if (isPercent) {
      setCardData({
        ...cardData,
        styles: {
          ...cardData.styles,
          [e.target.className]: {
            ...cardData.styles[e.target.className],
            [e.target.name]: `${e.target.value}%`,
          },
        },
      });
    } else if (isColor) {
      setCardData({
        ...cardData,
        styles: {
          ...cardData.styles,
          [e.target.className]: {
            ...cardData.styles[e.target.className],
            [e.target.name]: e.target.value,
          },
        },
      });
    } else if (isPixels) {
      setCardData({
        ...cardData,
        styles: {
          ...cardData.styles,
          [e.target.className]: {
            ...cardData.styles[e.target.className],
            [e.target.name]: `${e.target.value}px`,
          },
        },
      });
    } else if (isText) {
      setCardData({
        ...cardData,
        styles: {
          ...cardData.styles,
          [e.target.className]: {
            ...cardData.styles[e.target.className],
            [e.target.name]: e.target.value,
          },
        },
      });
    }
  };

  const resetAll = (e) => {
    e.preventDefault();

    setCardData({
      ...initialState,
      styles: defaultStyles,
    });
  };

  const data = {
    cardData,
    setCardData,
    handleInputs,
    handleAdditionalButton,
    handleEditAdditionalButton,
    handleEditStylesAdditionalButton,
    handleDeleteAdditionalButton,
    handleImages,
    deleteImage,
    handleBoolean,
    handleSocialMedia,
    handleRemoveSocial,
    actualizeSocial,
    handleStyles,
    resetStyles,
    resetSocialColors,
    resetAll,
  };

  return (
    <CardDataContext.Provider value={data}>{children}</CardDataContext.Provider>
  );
};

export { CardDataProvider };

export default CardDataContext;
