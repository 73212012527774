import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { CardDataProvider } from "./cards/newTemplate/CardData.provider";
import { useSelector } from "react-redux";
import CardModal from "./cards/newTemplate/CardModal";
import { getRoles } from "../../api/enterprise";
import { getTemplates } from "../../api/card";
import TemplateCard from "./cards/TemplateCard";

const Cards = () => {
  const [roles, setRoles] = useState([]);
  const [templates, setTemplates] = useState([]);
  const auth = useSelector((state) => state.auth);
  const getData = async () => {
    const res = await getRoles(auth._id);
    if (res.status) {
      setRoles(res.data);
    }
    const templatesRes = await getTemplates(auth._id);
    if (templatesRes.status) {
      setTemplates(templatesRes.data);
    }
  };
  useEffect(() => {
    getData();
  }, [auth]);

  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        animate={{ transform: "scale(1)" }}
        transition={{ duration: 0.45 }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography variant="h5">Tarjetas Biznes</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {templates &&
                templates[0] &&
                templates.map((item, index) => (
                  <Grid item xs={12} md={4} xl={3} key={index}>
                    <TemplateCard template={item} setIsEditing={setIsEditing} />
                  </Grid>
                ))}

              <Grid item xs={12} md={4} xl={3}>
                <Card
                  sx={{ height: "450px", maxWidth: "350px", cursor: "pointer" }}
                  onClick={() => {
                    setIsCreating(!isCreating);
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      height: "100%",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size={"6x"}
                      color="#777777"
                    />
                    <Typography color={"text.main"}>
                      Agregar nueva plantilla
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <CardDataProvider auth={auth}>
        {isCreating && (
          <CardModal setIsCreating={setIsCreating} roles={roles} />
        )}

        {isEditing && (
          <CardModal
            setIsCreating={setIsEditing}
            isEditing={isEditing}
            roles={roles}
          />
        )}
      </CardDataProvider>
    </>
  );
};

export default Cards;
