import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  createInvitation,
  createMasiveInvitation,
  getRoles,
} from "../../api/enterprise";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import env from "../../env";
import LoadingModal from "../common/LoadingModal";

const NewUsers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useSelector((state) => state.auth);
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [masiveRoleName, setMasiveRoleName] = useState("");
  const [email, setEmail] = useState("");
  const [defaultLink, setDefaultLink] = useState("")
  const [excel, setExcel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidLInks, setInvalidLInks] = useState([])
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await getRoles(auth._id);
    if (res.status) {
      setRoles(res.data);
    }
  };

  const handleChangeSelect = (event) => {
    setRoleName(event.target.value);
  };

  const handleChangeSelectMasive = (event) => {
    setMasiveRoleName(event.target.value);
  };

  const validateEmail = (valor) => {
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        valor
      )
    )
      return true;
  };

  const handleSubmitInvitation = async () => {
    if (validateEmail(email)) {
      setIsLoading(true);
      const res = await createInvitation(auth._id, email, roleName, defaultLink);
      if (res.status) {
        enqueueSnackbar(res.message, { variant: "success" });
        setTimeout(() => {
          setEmail("");
        }, 1000);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar("Ingrese un mail válido", { variant: "error" });
    }
    setIsLoading(false);
  };

  const handleGetExcelEmails = async () => {
    const formData = new FormData();
    formData.append("excel", excel);
    formData.append("role", masiveRoleName);
    const res = await createMasiveInvitation(auth._id, formData);
    if (res) return res;
  };

  const handleSubmitSingleInvitationExcel = async (id, mail) => {
    await createInvitation(id, mail, [masiveRoleName]);
  };

  const handleSubmitInvitationExcel = async () => {
    const res = await handleGetExcelEmails();
    if (res.status) {
      setInvalidLInks(res.invalidLinks);
      if (res && res.data && res.data[0]) {
        try {
          res.data.map((mail) => {
            const mailRegex = new RegExp( /[^@]+@[^\.]+\..+/g);
            const mailVerif = mailRegex.test(mail) ? mail : null;
            if(mailVerif) handleSubmitSingleInvitationExcel(auth._id, mail);
          });

          enqueueSnackbar("Invitaciones enviadas con éxito", {
            variant: "success",
          });
        } catch (error) {
          enqueueSnackbar("Error al subir archivo", {
            variant: "error",
          });
        }
      }
      setTimeout(() => {
        setExcel(null);
      }, 1000);
    }
  };

  return (
    <>
      <motion.div
        className="user_card"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        animate={{ transform: "scale(1)" }}
        transition={{ duration: 0.45 }}
      >
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", textAlign: "center" }}
        >
          <Grid item xs={10}>
            <Typography variant="h5">
              Enviar invitación para unirse a la empresa por email
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              value={roleName}
              onChange={handleChangeSelect}
              sx={{
                width: "80%",
                mt: 3,
                ml: 1,
              }}
            >
              <MenuItem value="">
                <ListItemText primary="Sin rol" />
              </MenuItem>
              {roles &&
                roles[0] &&
                roles.map((role) => (
                  <MenuItem key={role._id} value={role._id}>
                    <ListItemText primary={role.name} />
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              placeholder="Email del usuario"
              name="email"
              type="email"
              value={email}
              fullWidth
              onChange={(evt) => setEmail(evt.target.value)}
              sx={{ my: 5 }}
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              placeholder="Link de card (opcional)"
              name="defaultLink"
              type="defaultLink"
              value={defaultLink}
              fullWidth
              onChange={(evt) => setDefaultLink(evt.target.value)}
              sx={{ my: 5 }}
            />
            <button
              className="main_button"
              style={{ marginBottom: 20 }}
              onClick={handleSubmitInvitation}
            >
              Enviar invitación
            </button>
          </Grid>


          <Grid item xs={10}>
            <Typography variant="h5">
              Subir planilla de excel para invitaciones masivas
            </Typography>
            <a
              href={`${env.API_URL}/download/template-excel-addusers`}
              target="_blank"
            >
              <button
                className="secondary_button"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Descargar modelo de planilla
              </button>
            </a>

            {invalidLInks.length > 0 && (
              <div>
                <Typography variant="h6" style={{ color: "red" }}>
                  Estos links ya están en uso, no se envió la invitación para:
                </Typography>
                  <Typography variant="h6" style={{ color: "red" }}>
                    {invalidLInks.join(", ")}
                  </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={10} md={5}>
            <Select
              value={masiveRoleName}
              onChange={handleChangeSelectMasive}
              sx={{
                width: "80%",
                mt: 3,
                ml: 1,
              }}
            >
              <MenuItem value="">
                <ListItemText primary="Sin rol" />
              </MenuItem>
              {roles &&
                roles[0] &&
                roles.map((role) => (
                  <MenuItem key={role._id} value={role._id}>
                    <ListItemText primary={role.name} />
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={10} md={5}>
            <TextField
              variant="standard"
              type="file"
              fullWidth
              onChange={(evt) => {
                setExcel(evt.target.files[0]);
              }}
              sx={{ my: 5 }}
            />
          </Grid>
          <Grid item xs={10}>
            <button
              className="main_button"
              style={{ marginBottom: 20 }}
              onClick={handleSubmitInvitationExcel}
            >
              Enviar planilla
            </button>
          </Grid>
        </Grid>
      </motion.div>
      {isLoading && <LoadingModal msg="Enviando..." />}
    </>
  );
};

export default NewUsers;
