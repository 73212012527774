import React, { useContext, useState } from "react";
import CardDataContext from "./CardData.provider";
import "./CardModalFormData.scss";
import { useSelector } from "react-redux";
import CheckIfMembership from "./CheckIfMembership";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const CardModalFormData = () => {
  const {
    role,
    cardData,
    handleInputs,
    handleImages,
    handleBoolean,
    handleAdditionalButton,
    handleEditAdditionalButton,
    handleDeleteAdditionalButton,
    deleteImage
  } = useContext(CardDataContext);
  const auth = useSelector((state) => state.auth);
  console.log(cardData);
  if (role)
    return (
      <form className="card_modal_form_content" id="card_modal_form_data">
        <h2>NUEVA TARJETA DE PLANTILLA</h2>

        <div className="form_section images">
          <h3>Imagenes</h3>

          <div className="form_group">
            <label htmlFor="">Imágen de portada (opcional)</label>

            <CheckIfMembership value="editBannerImage">
              <>
                <label htmlFor="coverPhoto" className="pick_image_label">
                  Seleccionar archivo
                </label>
                {cardData.coverPhotoUrl && (
                  <p style={{ textAlign: "center", color: "green" }}>
                    Imágen seleccionada

                    <FontAwesomeIcon icon={faTrash} color="red" style={{
                      marginLeft: 10,
                      cursor: "pointer"
                    }} onClick={(e) => {
                      e.preventDefault();
                      deleteImage("coverPhoto");
                    }} />
                  </p>
                )}
                <input
                  type="file"
                  style={{ cursor: "pointer" }}
                  name="coverPhoto"
                  id="coverPhoto"
                  onChange={(e) => handleImages(e)}
                />
              </>
            </CheckIfMembership>
          </div>

          <div className="form_group">
            <label htmlFor="">Imágen de perfil (opcional)</label>
            <CheckIfMembership value="editUserImage">
              <>
                <label htmlFor="profilePhoto" className="pick_image_label">
                  Seleccionar archivo
                </label>
                {cardData.profilePhotoUrl && (
                  <p style={{ textAlign: "center", color: "green" }}>
                    Imágen seleccionada

                    <FontAwesomeIcon icon={faTrash} color="red" style={{
                      marginLeft: 10,
                      cursor: "pointer"
                    }} onClick={(e) => {
                      e.preventDefault();
                      deleteImage("profilePhoto");
                    }} />
                  </p>
                )}
                <input
                  type="file"
                  style={{ cursor: "pointer" }}
                  name="profilePhoto"
                  id="profilePhoto"
                  onChange={(e) => handleImages(e)}
                />
              </>
            </CheckIfMembership>
          </div>

          <div className="form_group">
            <label htmlFor="logo">Logo de empresa (opcional)</label>

            <CheckIfMembership value="editLogo">
              <>
                <label htmlFor="logo" className="pick_image_label">
                  Seleccionar archivo
                </label>
                {cardData.logoUrl && (
                  <p style={{ textAlign: "center", color: "green" }}>
                    Imágen seleccionada

                    <FontAwesomeIcon icon={faTrash} color="red" style={{
                      marginLeft: 10,
                      cursor: "pointer"
                    }} onClick={(e) => {
                      e.preventDefault();
                      deleteImage("logo");
                    }} />
                  </p>
                )}
                <input
                  type="file"
                  style={{ cursor: "pointer" }}
                  name="logo"
                  id="logo"
                  onChange={(e) => handleImages(e)}
                />
              </>
            </CheckIfMembership>
          </div>
        </div>

        <div className="form_section">
          <h3>Información</h3>

<div className="form_group full">
  <label htmlFor="name">Nombre</label>
    <input
    type="text"
      name="name"
      id="name"
      value={cardData.name}
      onChange={(e) => handleInputs(e)}
    />
</div>

          <div className="form_group full">
            <label htmlFor="cellphone">Número de teléfono</label>
            <CheckIfMembership value="editPhoneNumber">
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ fontSize: 18, marginRight: 15 }}>+56</p>
                  <input
                    type="number"
                    name="cellphone"
                    id="cellphone"
                    value={cardData.cellphone}
                    onChange={(e) => handleInputs(e)}
                    defaultValue="+"
                  />
                </div>
              </>
            </CheckIfMembership>
          </div>

          <div className="form_group full">
            <label htmlFor="biography">Descríbete en 150 carácteres </label>
            <CheckIfMembership value="editDescription">
              <textarea
                name="biography"
                id="biography"
                value={cardData.biography}
                onChange={(e) => handleInputs(e)}
                maxLength={150}
              />
            </CheckIfMembership>
          </div>
        </div>

        <div className="form_group full">
          <label htmlFor="cardLink">
            ¿Cómo quieres que encuentren tu tarjeta?
            <br />
            (en caso de llenar este campo, le asignaremos un valor aleatorio)
          </label>

          <div
            className="cardlink_input"
            style={{ display: "flex", alignItems: "center", marginTop: 15 }}
          >
            <p style={{ fontSize: 20, paddingLeft: 10 }}>
              https://empresas.biznes.cl/
            </p>

            <CheckIfMembership value="editUrl">
              <input
                type="text"
                name="cardLink"
                id="cardLink"
                value={cardData.cardLink}
                style={{
                  backgroundColor: "white",
                  padding: "8px 5px",
                }}
                onChange={(e) => handleInputs(e)}
              />
            </CheckIfMembership>
          </div>
        </div>

        <div className="form_section">
          <div className="form_group full vcard">
            <h3>Habilitar descarga de tarjeta virtual</h3>
            <CheckIfMembership value="editDownload">
              <div
                className=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  style={{
                    marginBottom: 0,
                    padding: "10px 30px",
                    background: "var(--color-logo)",
                    border: "1px solid black",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleBoolean("vcardWants");
                  }}
                >
                  {cardData.vcardWants ? "Deshabilitar" : "Habilitar"}
                </button>
              </div>
            </CheckIfMembership>
            <p className="disclaimer" style={{ marginTop: 20 }}>
              El formato .vcf (también conocido como V Card o Virtual Card) es
              un formato estándar para el intercambio de información personal,
              específicamente tarjetas personales electrónicas.
            </p>

            <p className="disclaimer">
              La información que tenga en la tarjeta Biznes Card se guardará, a
              su vez, en un archivo .vcf que podrá descargar y compartir con
              otras personas.
            </p>
          </div>
        </div>

        <div className="form_section">
          <div className="form_group full vcard">
            <h3>Habilitar botón de llamar</h3>
            <CheckIfMembership value="editCall">
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  style={{
                    marginBottom: 0,
                    padding: "10px 30px",
                    background: "var(--color-logo)",
                    border: "1px solid black",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleBoolean("addContact");
                  }}
                >
                  {cardData.addContact ? "Deshabilitar" : "Habilitar"}
                </button>
              </div>
            </CheckIfMembership>
            <p className="disclaimer" style={{ marginTop: 20 }}>
              Al habilitar esta opción, los usuarios podrán añadir su tarjeta a
              la lista de contactos de su dispositivo móvil.
            </p>
          </div>
        </div>

        <div className="form_section">
          <div className="form_group full vcard">
            <h3>Agregar botones adicionales</h3>
            <CheckIfMembership value="editAdditionalButtons">
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    style={{
                      marginBottom: 0,
                      padding: "10px 30px",
                      background: "var(--color-logo)",
                      border: "1px solid black",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (cardData.additionalButtons) {
                        handleAdditionalButton();
                      }
                    }}
                  >
                    Agregar botón adicional
                  </button>
                </div>

                {cardData.additionalButtons &&
                  cardData.additionalButtons[0] &&
                  cardData.additionalButtons.map((button, index) => (
                    <div
                      className="form_section"
                      style={{ marginTop: 30 }}
                      key={index}
                    >
                      <h4>Botón #{index + 1}</h4>
                      <button
                        className="delete_button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteAdditionalButton(index);
                        }}
                      >
                        Eliminar botón
                      </button>
                      <div className="form_group full">
                        <label htmlFor="text">Texto</label>
                        <input
                          type="text"
                          name="text"
                          id="text"
                          value={cardData.additionalButtons[index].text}
                          onChange={(e) => handleEditAdditionalButton(e, index)}
                        />
                      </div>

                      <div className="form_group full">
                        <label htmlFor="link">Link</label>
                        <input
                          type="text"
                          name="link"
                          id="link"
                          value={cardData.additionalButtons[index].link}
                          onChange={(e) => handleEditAdditionalButton(e, index)}
                        />
                      </div>
                    </div>
                  ))}
              </>
            </CheckIfMembership>
          </div>
        </div>
      </form>
    );
};

export default CardModalFormData;
