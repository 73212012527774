import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faLock,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { addContact, removeContact } from "../../api/agenda";
import { useDispatch, useSelector } from "react-redux";
import { authLogin } from "../../redux/actions/auth.actions";
import { useSnackbar } from "notistack";

const UserCard = ({ user }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleAddContact = async () => {
    const token = localStorage.getItem("token");
    const res = await addContact(user._id);
    if (res.status) {
      console.log(res);
      enqueueSnackbar(res.message, { variant: "success" });
      dispatch(
        authLogin({
          ...res.data,
          userType: "user",
          token,
        })
      );
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleDeleteContact = async () => {
    const token = localStorage.getItem("token");
    const res = await removeContact(user._id);
    if (res.status) {
      console.log(res);
      enqueueSnackbar(res.message, { variant: "success" });
      dispatch(
        authLogin({
          ...res.data,
          userType: "user",
          token,
        })
      );
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  return (
    <>
      {user && (
        <motion.div
          className="user_card"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          animate={{ transform: "scale(1)" }}
          transition={{ duration: 0.45 }}
        >
          <Card
            component={Paper}
            elevation={5}
            sx={{
              minHeight: 150,
              backgroundColor: "primary.main",
              mb: 3,
            }}
          >
            <CardContent>
              <Grid
                container
                spacing={1}
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <Grid item xs={12} md={2}>
                  <img
                    src={user && user.userImage}
                    width="100px"
                    height="100px"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" sx={{ fontWeight: "400" }}>
                    {user.firstName} {user.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" sx={{ fontWeight: "400" }}>
                    {user.email}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Grid container>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      {user && user.enterprise && (
                        <Tooltip title="Cuenta de empresa" placement="top">
                          <FontAwesomeIcon
                            icon={faBuilding}
                            size="xl"
                            color="#444444"
                            style={{ margin: "0 10px" }}
                          />
                        </Tooltip>
                      )}
                      {user && user.privacyType !== "public" && (
                        <Tooltip title="Cuenta privada" placement="top">
                          <FontAwesomeIcon
                            icon={faLock}
                            size="xl"
                            color="#444444"
                            style={{ margin: "0 10px" }}
                          />
                        </Tooltip>
                      )}
                      {(auth.agendaFriends.enterprise[0] &&
                        auth.agendaFriends.enterprise.includes(user._id)) ||
                      (auth.agendaFriends.networking[0] &&
                        auth.agendaFriends.networking.includes(user._id)) ? (
                        <Tooltip title="Eliminar contacto" placement="top">
                          <FontAwesomeIcon
                            icon={faUserMinus}
                            size="xl"
                            color="#444444"
                            style={{ margin: "0 10px", cursor: "pointer" }}
                            onClick={handleDeleteContact}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Agregar contacto" placement="top">
                          <FontAwesomeIcon
                            icon={faUserPlus}
                            size="xl"
                            color="#444444"
                            style={{ margin: "0 10px", cursor: "pointer" }}
                            onClick={handleAddContact}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                    {user && user.privacyType === "public" && (
                      <Grid item xs={12}>
                        <button
                          className="secondary_button"
                          onClick={() =>
                            navigate(
                              `/agenda_biznes/user_profile/${
                                user._id
                              }?user_type=${
                                user.enterprise ? "enterprise" : "individual"
                              }`
                            )
                          }
                        >
                          Ver perfil
                        </button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </motion.div>
      )}
    </>
  );
};

export default UserCard;
