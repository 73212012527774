import React, { useState, useEffect } from "react";
import { getTemplateById } from "../api/card";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cover from "../assets/portadaBiznes.gif";
import logoSmall from "../assets/BiznesLogo.png";
import SocialMedia from "../assets/CardModalSocialMedia.data";
import CustomMedia from "../assets/CardModalCustomMedia.data";
import env from "../env";

const CardTemplatePreview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [card, setCard] = useState({});
  const { id } = useParams();

  const getData = async () => {
    const res = await getTemplateById(id);
    if (res.status) {
      setCard(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  const findIconSocial = (name) => {
    let icon = SocialMedia.find((socialMedia) => socialMedia.name === name);
    if (!icon)
      icon = CustomMedia.find((socialMedia) => socialMedia.name === name);
    return icon.icon;
  };

  if (isLoading)
    return (
      <div className="loading_container">
        <h1>Loading...</h1>
        <TailSpin color="black" />
      </div>
    );

  if (!isLoading)
    return (
      <div className="container_card_pc">
        <div
          className="preview_content"
          style={{
            backgroundColor: card.cardStyle.body.backgroundColor || "white",
            marginBottom: 50,
          }}
        >
          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_header"
          >
            <img
              src={card.coverPhoto ? card.coverPhoto : cover}
              alt="Prevista portada"
              id="preview_cover_photo"
            />

            <motion.div
              animate={{ transform: "scale(1)" }}
              className="preview_header_user"
            >
              <img
                src={card.perfilImage}
                alt="Prevista foto de perfil"
                id="preview_profile_photo"
                style={card.cardStyle.profilePhoto}
              />
              <img
                src={card.logoPhoto ? card.logoPhoto : logoSmall}
                alt="Prevista del logo"
                id="preview_profile_logo"
                style={card.cardStyle.logo}
              />
            </motion.div>
          </motion.div>

          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_body"
          >
            <motion.div
              animate={{ transform: "scale(1)" }}
              className="preview_body_user"
            >
              <h2
                id="preview_username"
                style={{
                  ...card.cardStyle.name,
                  textAlign: card.cardStyle.name.textAlign,
                }}
              >
                {card.name ? card.name : "Nombre de usuario"}
              </h2>

              {(card.jobPosition || card.jobEntity) && (
                <motion.div
                  animate={{ transform: "scale(1)" }}
                  className="preview_body_user_job"
                >
                  <h3 id="preview_job_position" style={card.cardStyle.job}>
                    {card.jobPosition && card.jobPosition}{" "}
                    {card.jobPosition && card.jobEntity && "-"}{" "}
                    {card.jobEntity && card.jobEntity}
                  </h3>
                </motion.div>
              )}

              <motion.div
                animate={{ transform: "scale(1)" }}
                className="preview_body_user_email"
              >
                <h3 id="preview_email" style={card.cardStyle.email}>
                  <a href={`mailto:${card.email}`} style={{ color: "inherit" }}>
                    {card.email ? card.email : "usuario@email.com"}
                  </a>
                </h3>
              </motion.div>

              <motion.div
                animate={{ transform: "scale(1)" }}
                className="preview_body_user_biography"
              >
                <p id="preview_biography" style={card.cardStyle.biography}>
                  {card.biography || ""}
                </p>
              </motion.div>
            </motion.div>

            {card.vcardWants && (
              <motion.a
                href={card.vcard}
                animate={{ transform: "scale(1)" }}
                className="preview_body_vcard"
                style={{ cursor: "pointer" }}
              >
                <button
                  style={{ ...card.cardStyle.buttonVCard, cursor: "pointer" }}
                >
                  Agregar contacto
                </button>
              </motion.a>
            )}

            {card.addContact && (
              <motion.div
                animate={{ transform: "scale(1)" }}
                className="preview_body_vcard"
              >
                <button
                  style={{ ...card.cardStyle.buttonContact, cursor: "pointer" }}
                >
                  <a
                    href={`tel:+56${card.cellphone}`}
                    style={{
                      color: card.cardStyle.buttonContact.color,
                      textDecoration: "none",
                    }}
                  >
                    Llamar
                  </a>
                </button>
              </motion.div>
            )}

            {card.additionalButtons &&
              card.additionalButtons[0] &&
              card.additionalButtons.map((data, index) => (
                <a
                  href={
                    card.additionalButtons[index] &&
                    card.additionalButtons[index].link
                  }
                  target="_blank"
                >
                  <motion.div
                    animate={{ transform: "scale(1)" }}
                    className="preview_body_vcard"
                  >
                    <button
                      style={
                        card.additionalButtons[index] &&
                        card.additionalButtons[index].styles && {
                          ...card.additionalButtons[index].styles,
                          cursor: "pointer",
                        }
                      }
                    >
                      <a
                        style={
                          card.additionalButtons[index] &&
                          card.additionalButtons[index].styles
                        }
                      >
                        {card.additionalButtons[index] &&
                          card.additionalButtons[index].text}
                      </a>
                    </button>
                  </motion.div>
                </a>
              ))}

            <motion.div
              animate={{ transform: "scale(1)" }}
              className="preview_body_social"
            >
              <motion.div
                animate={{ transform: "scale(1)" }}
                className="preview_body_social_icons"
              >
                <motion.div
                  animate={{ transform: "scale(1)" }}
                  className="favorites"
                >
                  {card.socialMedia
                    .filter((el) => el.favorite)
                    .map((icon, index) => (
                      <a
                        href={icon.url}
                        key={index}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          borderRadius:
                            card.cardStyle.buttonSocialFavorite.borderRadius,
                          backgroundColor:
                            card.cardStyle.buttonSocialFavorite
                              .backgroundColor || icon.color,
                        }}
                        className="favorite"
                      >
                        <FontAwesomeIcon
                          icon={findIconSocial(icon.name)}
                          style={{
                            color:
                              card.cardStyle.buttonSocialFavorite.color ||
                              icon.contrast,
                          }}
                        />
                      </a>
                    ))}
                </motion.div>

                {card.socialMedia
                  .filter((el) => !el.favorite)
                  .map((icon, index) => (
                    <a
                      href={icon.url}
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        borderRadius: card.cardStyle.buttonSocial.borderRadius,
                        backgroundColor:
                          card.cardStyle.buttonSocial.backgroundColor ||
                          icon.color,
                      }}
                      className="nofavorite"
                    >
                      <FontAwesomeIcon
                        icon={findIconSocial(icon.name)}
                        style={{
                          color:
                            card.cardStyle.buttonSocial.color || icon.contrast,
                        }}
                      />
                    </a>
                  ))}
              </motion.div>
            </motion.div>
          </motion.div>

          {card.membership && card.membership[0].name === "Básica" && (
            <a href={env.FRONTEND_URL}>
              <p style={{ textAlign: "center", color: "rgb(70, 70, 70)" }}>
                Membresía básica{" "}
                <a style={{ color: "rgb(70, 70, 70)" }} href={env.FRONTEND_URL}>
                  Biznes
                </a>
                , para conocer más visítenos en {env.FRONTEND_URL}.
              </p>
            </a>
          )}
        </div>
      </div>
    );
};

export default CardTemplatePreview;
