import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import cover from "../../assets/portadaBiznes.gif";
import logoSmall from "../../assets/BiznesLogo.png";
import QrModal from "../common/QrModal";

const BiznesCard = ({ template, setIsEditing, setIsDeleting }) => {
  const location = useLocation()
  const [modal, setModal] = useState(false);
  return (
    <>
      <Card
        sx={{
          height: "550px",
          maxWidth: "350px",
          backgroundColor: "secondary.main",
        }}
      >
        <CardContent sx={{ 
          height: ` calc(100% )`,
          display: "flex",
          flexDirection: "column",
        }}>

          <Grid container>
            <Grid item xs={6}>
              <Typography
                color="text.white"
                sx={{ textAlign: "start", py: 1, cursor: "pointer" }}
                onClick={() => setIsEditing(template)}
              >
                {location.pathname.includes("enterprise") ? null : "Editar"}
              </Typography>
            </Grid>

            <Grid item xs={6}>

              <Typography
                color="text.white"
                sx={{ textAlign: "end", py: 1, cursor: "pointer" }}
                onClick={() => setIsDeleting(template._id)}
              >
                {location.pathname.includes("enterprise") ? null : "Eliminar"}
              </Typography>
            </Grid>
          </Grid>

          <Card sx={{ height: "100%" }}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Grid container sx={{ textAlign: "center" }} spacing={1}>
                {
                  (template.coverPhoto || template.perfilImage) && <Grid item xs={12} sx={{ height: "100px" }}>
                    {template.coverPhoto && <img
                      src={template.coverPhoto === "default" ? cover : template.coverPhoto}
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />}

                    {template.perfilImage && <img
                      src={template.perfilImage === "default" ? logo : template.perfilImage}
                      style={{
                        borderRadius: "50%",
                        objectFit: "cover",
                        height: "80px",
                        width: "80px",
                        transform: template.coverPhoto ? 'translateY(-40px)' : 'translateY(0px)',
                      }}
                    />}
                  </Grid>
                }

                {template.logoPhoto && (
                  <Grid item xs={12}>
                    <img
                      src={template.logoPhoto === "default" ? logoSmall : template.logoPhoto}
                      style={{
                        borderRadius: "50%",
                        objectFit: "cover",
                        height: "40px",
                        width: "40px",
                        transform: `translate(${template.perfilImage ? '20px' : '0px'
                          }, ${!template.coverPhoto ? !template.perfilImage ? '0px' : '-40px' : '15px'

                          })`,
                      }}
                    />
                  </Grid>
                )}
                
                <Grid item xs={12} style={{
                  marginTop: !template.logoPhoto ? '50px' : '0px'
                }}>
                  <Typography>{template.name && template.name}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>{template.email && template.email}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ my: 3 }}>
                  <Link to={`/${template.cardLink}`} target="_blank">
                    <button
                      className="main_button"
                      style={{ color: "black", width: "100%" }}
                    >
                      Ver tarjeta
                    </button>
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <img
            src={template.imageQr}
            alt="Card Qr"
            width={60}
            height={60}
            style={{
              marginTop: 10,
              alignSelf: "center",
            }}
            onClick={() => setModal(!modal)}
          />

        </CardContent>
      </Card>
      {modal && (
        <QrModal
          qr={template.imageQr}
          link={template.downloadQr}
          setModal={setModal}
        />
      )}
    </>
  );
};

export default BiznesCard;
