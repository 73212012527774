import backendApi from "./config";

const login = async (payload) => {
  try {
    const { data } = await backendApi.post(`/auth/login`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const register = async (payload) => {
  try {
    const { data } = await backendApi.post(`/enterprise`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const checkUser = async () => {
  try {
    const { data } = await backendApi.get(`/auth/whoiam`);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const passwordRecovery = async (payload) => {
  try {
    const { data } = await backendApi.post(`/auth/changePassword`, {
      email: payload,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const verifyRecoveryCode = async (email, code) => {
  try {
    const { data } = await backendApi.post(`/auth/verifyCode`, {
      email: email,
      code: code,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const sendNewPassword = async (id, payload) => {
  try {
    const { data } = await backendApi.put(`/auth/${id}`, {
      newPassword: payload,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
export {
  login,
  register,
  checkUser,
  passwordRecovery,
  verifyRecoveryCode,
  sendNewPassword,
};
