import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import routes from "../../router/routes";

const SecondStep = ({ code, setCode, handleSubmitCode }) => {
  return (
    <Grid container sx={{ py: 3, px: "20%", textAlign: "left" }}>
      <Grid item xs={12}>
        <Typography variant="body">
          Revise su casilla de email e ingrese su código
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 3 }}>
        <TextField
          name="code"
          value={code}
          fullWidth
          onChange={(evt) => setCode(evt.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ py: 3, textAlign: "center" }}>
        <button
          className="secondary_button"
          style={{ width: "100%" }}
          onClick={() => handleSubmitCode()}
        >
          Enviar
        </button>
      </Grid>
      <Grid item xs={12} sx={{ py: 1, textAlign: "center" }}>
        <Link to={routes.login}>
          <Typography variant="body">Volver a inicio de sesión</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default SecondStep;
