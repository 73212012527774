import React, { useContext } from "react";
import "./MobilePreviewContent.scss";
import CardDataContext from "./CardData.provider";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import SocialMedia from "../../../assets/CardModalSocialMedia.data";
import CustomMedia from "../../../assets/CardModalCustomMedia.data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoSmall from "../../../assets/BiznesLogo.png";
import cover from "../../../assets/portadaBiznes.gif";

const MobilePreviewContent = () => {
  const { cardData, handleInputs } = useContext(CardDataContext);
  const auth = useSelector((state) => state.auth);

  const greyImage =
    "https://res.cloudinary.com/syphhy/image/upload/v1672684987/grey-background-07_w4ukhq.jpg";

  const findIconSocial = (name) => {
    let icon = SocialMedia.find((socialMedia) => socialMedia.name === name);
    if (!icon)
      icon = CustomMedia.find((socialMedia) => socialMedia.name === name);
    return icon.icon;
  };

  return (
    <div
      className="preview_content"
      style={{ backgroundColor: cardData.styles.body.backgroundColor }}
    >
      <motion.div
        animate={{ transform: "scale(1)" }}
        className="preview_header"
        style={{
          marginBottom: cardData.coverPhotoUrl ? '80px' : "0",
          height: (cardData.coverPhotoUrl || cardData.profilePhotoUrl) ? '30%' : '10%',
          marginTop: (cardData.coverPhotoUrl || cardData.profilePhotoUrl) ? '0' : '20px',
        }}
      >
        {
          cardData.coverPhotoUrl && <img
            src={
              cardData.coverPhotoUrl !== "default"
                ? cardData.coverPhotoUrl || cover
                : cover
            }
            alt="Prevista portada"
            id="preview_cover_photo"
          />
        }

        <motion.div
          animate={{ transform: "scale(1)" }}
          className="preview_header_user"
          style={
            {
              position: cardData.coverPhotoUrl ? "absolute" : "relative",
              left: "0",
              display: "flex",
              justifyContent: cardData.coverPhotoUrl ? "center" : "center",
              width: '100%'
            }
          }
        >
          {
            cardData.profilePhotoUrl &&
            <img
              src={
                cardData.profilePhotoUrl !== "default"
                  ? cardData.profilePhotoUrl || greyImage
                  : greyImage
              }
              alt="Prevista foto de perfil"
              id="preview_profile_photo"
              style={cardData.styles.profilePhoto}
            />
          }

          {
            cardData.logoUrl && <img
              src={
                cardData.logoUrl !== "default" 
                  ? cardData.logoUrl || logoSmall
                  : logoSmall
              }
              alt="Prevista del logo"
              id="preview_profile_logo"
              style={{
                ...cardData.styles.logo,
                right: cardData.profilePhotoUrl ? 'calc(50% - 70px)' : 'calc(50% - 30px)' 
              }}
            />
          }
        </motion.div>
      </motion.div>

      <motion.div animate={{ transform: "scale(1)" }} className="preview_body">
        <motion.div
          animate={{ transform: "scale(1)" }}
          className="preview_body_user"
        >
          <h2
            id="preview_username"
            style={{
              ...cardData.styles.name,
              textAlign: cardData.styles.name.textAlign,
            }}
          >
            {cardData.name}
          </h2>

          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_body_user_email"
          >
            <h3 id="preview_email" style={cardData.styles.email}>
              {auth.email || "biznes@contacto.com"}
            </h3>
          </motion.div>

          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_body_user_biography"
          >
            <p id="preview_biography" style={cardData.styles.biography}>
              {cardData.biography || "Una breve descripción suya."}
            </p>
          </motion.div>
        </motion.div>

        {cardData.vcardWants && (
          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_body_vcard"
          >
            <button style={cardData.styles.buttonVCard}>
              Agregar contacto
            </button>
          </motion.div>
        )}

        {cardData.addContact && (
          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_body_vcard"
          >
            <button style={cardData.styles.buttonContact}>
              <a
                href={""}
                style={{
                  color: cardData.styles.buttonContact.color,
                  textDecoration: "none",
                }}
                onClick={(e) => e.preventDefault()}
              >
                Llamar a {`+56-${cardData.cellphone || "..."}`}
              </a>
            </button>
          </motion.div>
        )}

        {cardData.additionalButtons &&
          cardData.additionalButtons[0] &&
          cardData.additionalButtons.map((data, index) => (
            <motion.div
              animate={{ transform: "scale(1)" }}
              className="preview_body_vcard"
            >
              <button
                style={
                  cardData.additionalButtons[index] &&
                  cardData.additionalButtons[index].styles &&
                  cardData.additionalButtons[index].styles
                }
              >
                <a
                  href={
                    cardData.additionalButtons[index] &&
                    cardData.additionalButtons[index].link
                  }
                  style={
                    cardData.additionalButtons[index] &&
                    cardData.additionalButtons[index].styles
                  }
                  onClick={(e) => e.preventDefault()}
                >
                  {cardData.additionalButtons[index] &&
                    cardData.additionalButtons[index].text}
                </a>
              </button>
            </motion.div>
          ))}

        <motion.div
          animate={{ transform: "scale(1)" }}
          className="preview_body_social"
        >
          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_body_social_icons"
          >
            <motion.div
              animate={{ transform: "scale(1)" }}
              className="favorites"
            >
              {cardData.socialMedia &&
                cardData.socialMedia[0] &&
                cardData.socialMedia
                  .filter((el) => el.favorite)
                  .map((icon, index) => (
                    <a
                      href={icon.url}
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        borderRadius:
                          cardData.styles.buttonSocialFavorite.borderRadius,
                        backgroundColor:
                          cardData.styles.buttonSocialFavorite
                            .backgroundColor || icon.color,
                      }}
                      className="favorite"
                    >
                      <FontAwesomeIcon
                        icon={findIconSocial(icon.name)}
                        style={{
                          color:
                            cardData.styles.buttonSocialFavorite.color ||
                            icon.contrast,
                        }}
                      />
                    </a>
                  ))}
            </motion.div>

            {cardData.socialMedia &&
              cardData.socialMedia[0] &&
              cardData.socialMedia
                .filter((el) => !el.favorite)
                .map((icon, index) => (
                  <a
                    href={icon.url}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      borderRadius: cardData.styles.buttonSocial.borderRadius,
                      backgroundColor:
                        cardData.styles.buttonSocial.backgroundColor ||
                        icon.color,
                    }}
                    className="nofavorite"
                  >
                    <FontAwesomeIcon
                      icon={findIconSocial(icon.name)}
                      style={{
                        color:
                          cardData.styles.buttonSocial.color || icon.contrast,
                      }}
                    />
                  </a>
                ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default MobilePreviewContent;
