import {
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "../../api/enterprise";
import { motion } from "framer-motion";
import { cancelMembershipSuscription } from "../../api/memberships";
import { checkUser } from "../../api/auth";
import { authLogin } from "../../redux/actions/auth.actions";

const EditEnterprise = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: auth.name && auth.name,
    description: auth.description && auth.description,
    email: auth.email && auth.email,
    password: "",
    logo: null,
    coverImage: null,
  });

  const identifyUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res = await checkUser();
      if (res.status) {
        dispatch(
          authLogin({
            ...res.data.userToSend,
            userType: res.data.userType,
            token,
          })
        );
      }
    }
  };

  const updateForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateImagesForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("description", form.description);
    formData.append("email", form.email);
    if (form.password.length > 0) formData.append("password", form.password);
    form.logo && formData.append("logo", form.logo);
    form.coverImage && formData.append("coverImage", form.coverImage);
    const res = await updateUserInfo(auth._id, formData);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      identifyUser();
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const handleUnsubscribe = async () => {
    const res = await cancelMembershipSuscription();
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item xs={12} lg={6}>
        <motion.div
          className="user_card"
          whileHover={{ scale: 1.1, cursor: "pointer" }}
          whileTap={{ scale: 0.9 }}
          animate={{ transform: "scale(1)" }}
          transition={{ duration: 0.45 }}
        >
          <Card component={Paper} elevation={5} sx={{ minHeight: 500 }}>
            <CardContent sx={{ justifyContent: "center", textAlign: "center" }}>
              <Grid container sx={{ py: 5, px: "20%", textAlign: "left" }}>
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Nombre de la empresa
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="name"
                    disabled
                    value={form.name}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Descripción
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="description"
                    value={form.description}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="email"
                    disabled
                    value={form.email}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Contraseña
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="password"
                    value={form.password}
                    fullWidth
                    type="password"
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Logo
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="logo"
                    fullWidth
                    type="file"
                    onChange={(evt) => updateImagesForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Foto de portada
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="coverImage"
                    fullWidth
                    type="file"
                    onChange={(evt) => updateImagesForm(evt)}
                  />
                </Grid>

                <Grid item xs={12} sx={{ py: 3, textAlign: "center" }}>
                  <button
                    className="main_button"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    Actualizar datos
                  </button>
                </Grid>
                <Grid item xs={12} sx={{ py: 3, textAlign: "center" }}>
                  <button
                    className="delete_button"
                    style={{ width: "100%" }}
                    onClick={handleUnsubscribe}
                  >
                    Cancelar suscripción
                  </button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default EditEnterprise;
