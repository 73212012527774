import axios from "axios";
import env from "../env";
import backendApi from "./config";

const createNewUser = async (id, payload) => {
  try {
    const { data } = await backendApi.post(`/user/register/${id}`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const updateUserInfo = async (id, payload) => {
  try {
    const { data } = await backendApi.put(`/user/${id}`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getAgendaBiznes = async () => {
  try {
    const {data} = await axios.get(`${env.API_URL_ADMIN}/biznesagenda/getAllUsers`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

const getUserById = async (id) => {
  try {
    const {data} = await backendApi.get(`/user/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

const getUserByIdNetworking = async (id) => {
  try {
    const {data} = await axios.get(`${env.API_URL_ADMIN}/users/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

export { createNewUser, updateUserInfo, getAgendaBiznes, getUserById, getUserByIdNetworking };
