import React, { useEffect, useState } from 'react'
import styles from '../../pages/StatsCard.module.scss'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Tooltip,
    PointElement,
    LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register ChartJS components using ChartJS.register
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip
);

export default function Views({ card, filters, labels }) {
    const [views, setViews] = useState([])
    const [data, setData] = useState({
        labels: filters.from === filters.to ? Object.keys(views) : labels,
        datasets: [],
    })

    const options = {
        plugins: {
            legend: false
        },
        responsive: true,
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: filters.from === filters.to ? 'Horas' : 'Fechas',
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Vistas'
                }
            }
        }
    }

    useEffect(() => {
        if (filters.from && filters.to) {
            if (filters.from !== filters.to) {
                const filteredViews = card.views.filter(view => {
                    return view.date.split('T')[0] >= filters.from && view.date.split('T')[0] <= filters.to
                })

                const qtyPerDay = {}

                filteredViews.forEach(view => {
                    const date = view.date.split('T')[0].replace(/-/g, '/')

                    if (qtyPerDay[date]) {
                        qtyPerDay[date]++
                    } else {
                        qtyPerDay[date] = 1
                    }
                })
                setViews(qtyPerDay)
            } else {
                const filteredViews = card.views.filter(view => {
                    return view.date.split('T')[0] === filters.from
                })

                const qtyPerHour = {}

                filteredViews.forEach(view => {
                    const date = new Date(view.date)
                    const hour = `${date.getHours()}:00 hs`
                    if (qtyPerHour[hour]) {
                        qtyPerHour[hour]++
                    } else {
                        qtyPerHour[hour] = 1
                    }
                })
                setViews(qtyPerHour)
            }
        }
    }, [filters])

    useEffect(() => {
        setData({
            labels: filters.from === filters.to ? Object.keys(views) : labels,
            datasets: [
                {
                    label: "Vistas",
                    data: filters.from === filters.to ? Object.values(views) : Array(labels.length).fill(0).map((_, i) => {
                        return views[labels[i]] || 0
                    }, []),
                    borderColor: '#7577CD',
                    borderWidth: 4,
                    pointBorderColor: '#080957',
                    pointBorderWidth: 4,
                    tension: 0.4,
                    fill: true,
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                        gradient.addColorStop(0, "#7577CD");
                        gradient.addColorStop(1, "#7577CD00");
                        return gradient;
                    }
                }
            ]
        })
    }, [views])

    return (
        <div className={styles.stat} id={styles.views}>
            <h3>Vistas a la tarjeta por fecha u horas en un mismo dia</h3>
            <Line data={data} options={options} />
        </div>
    )
}
