import React from "react";
import { motion } from "framer-motion";
import { Grid } from "@mui/material";

const QrModal = ({ qr, link, setModal }) => {
  return (
    <div
      onClick={() => setModal(false)}
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.div id="finish_modal" animate={{ transform: "scale(1)" }}>
        <Grid container sx={{ textAlign: "center" }} spacing={2}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <img src={qr} alt="Qr" width={300} />
          </Grid>
          <Grid item xs={12}>
            <a
              href={link}
              onClick={() => setModal(false)}
              target="_blank"
              className="main_button"
            >
              DESCARGAR QR
            </a>
          </Grid>
        </Grid>
      </motion.div>
    </div>
  );
};

export default QrModal;
