import React from 'react'
import { Grid, Typography } from '@mui/material'
import Cards from '../components/userHome/Cards'

export default function CardsAdmin() {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Seleccione una tarjeta</Typography>
      </Grid>

      <Grid item xs={12}>
        <Cards cardsFromEnterprise />
      </Grid>
    </Grid>
  )
}
