import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import SocialMedia from "../../../assets/CardModalSocialMedia.data";
import CustomMedia from "../../../assets/CardModalCustomMedia.data";
import "./CardModalSocialMedia.scss";
import CardDataContext from "./CardData.provider";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import CheckIfMembership from "./CheckIfMembership";

const CardModalSocialMedia = () => {
  const [pickedMedia, setPickedMedia] = useState([]);
  const [mediaData, setMediaData] = useState(SocialMedia);
  const [customMediaData, setCustomMediaData] = useState(CustomMedia);
  const [error, setError] = useState(false);

  const auth = useSelector((state) => state.auth);

  const { cardData, handleSocialMedia, handleRemoveSocial, actualizeSocial } =
    useContext(CardDataContext);

  const handlePick = (icon) => {
    const isPicker = pickedMedia.find(
      (pickedIcon) => pickedIcon.name === icon.name
    );

    if (isPicker) {
      const newPickedMedia = pickedMedia.filter(
        (pickedIcon) => pickedIcon.name !== icon.name
      );
      setPickedMedia(newPickedMedia);
      handleRemoveSocial(icon);
    } else {
      setPickedMedia([...pickedMedia, icon]);
    }
  };

  const isPicked = (icon) => {
    const item = pickedMedia.find(
      (pickedIcon) => pickedIcon.name === icon.name
    );

    if (item) return true;
  };

  const findValue = (name) => {
    const pickedIcon = cardData.socialMedia.find(
      (socialMedia) => socialMedia.name === name
    );
    return pickedIcon ? pickedIcon.url : "";
  };

  const handleFavorite = (icon) => {
    const item = pickedMedia.find(
      (pickedIcon) => pickedIcon.name === icon.name
    );
    const newPickedMedia = pickedMedia.filter(
      (pickedIcon) => pickedIcon.name !== icon.name
    );
    const url = cardData.socialMedia.find(
      (socialMedia) => socialMedia.name === icon.name
    ).url;

    item.favorite = !item.favorite;
    item.url = url;

    newPickedMedia.unshift(item);

    actualizeSocial(newPickedMedia);
    setPickedMedia(newPickedMedia);
  };

  const isFavorite = (icon) => {
    const item = pickedMedia.find(
      (pickedIcon) => pickedIcon.name === icon.name
    );
    return item.favorite;
  };

  useEffect(() => {
    const pickedIcons = [];
    const fakePickedMedia = [];

    cardData.socialMedia.forEach((socialMedia) => {
      const pickedIcon = mediaData.find(
        (icon) => icon.name === socialMedia.name
      );
      pickedIcons.push({
        ...pickedIcon,
        url: socialMedia.url,
        favorite: socialMedia.favorite,
      });
    });

    cardData.socialMedia.forEach((socialMedia) => {
      const pickedIcon = customMediaData.find(
        (icon) => icon.name === socialMedia.name
      );
      pickedIcons.push({
        ...pickedIcon,
        url: socialMedia.url,
        favorite: socialMedia.favorite,
      });
    });

    pickedIcons.forEach((icon) => {
      const newMediaData = mediaData.find((media) => media.name === icon.name);
      if (newMediaData) fakePickedMedia.push(icon);
    });

    pickedIcons.forEach((icon) => {
      const newMediaData = customMediaData.find(
        (media) => media.name === icon.name
      );
      if (newMediaData) fakePickedMedia.push(icon);
    });

    setPickedMedia(fakePickedMedia);
  }, []);

  return (
    <form className="card_modal_socialmedia">
      <div className="socialmedia_container">
        <h2>Elija sus redes sociales</h2>
        <CheckIfMembership value="editSocialMedia">
          {mediaData.map((socialMedia, index) => (
            <div className="icon_container" key={index}>
              <FontAwesomeIcon
                icon={socialMedia.icon}
                className="social_icon"
                onClick={() => handlePick(socialMedia)}
                style={
                  isPicked(socialMedia)
                    ? {
                        background: socialMedia.color,
                        color: socialMedia.contrast,
                      }
                    : { color: "grey" }
                }
              />

              {isPicked(socialMedia) && (
                <FontAwesomeIcon
                  icon={faStar}
                  className="favorite_social"
                  style={{ color: isFavorite(socialMedia) ? "#D4AF37" : "" }}
                  onClick={(e) => handleFavorite(socialMedia)}
                />
              )}
            </div>
          ))}
        </CheckIfMembership>
        <h2>Elija sus iconos personalizados</h2>
        <CheckIfMembership value="editSocialMedia">
          {customMediaData.map((socialMedia, index) => (
            <div className="icon_container" key={index}>
              <FontAwesomeIcon
                icon={socialMedia.icon}
                className="social_icon"
                onClick={() => handlePick(socialMedia)}
                style={
                  isPicked(socialMedia)
                    ? {
                        background: socialMedia.color,
                        color: socialMedia.contrast,
                      }
                    : { color: "grey" }
                }
              />

              {isPicked(socialMedia) && (
                <FontAwesomeIcon
                  icon={faStar}
                  className="favorite_social"
                  style={{ color: isFavorite(socialMedia) ? "#D4AF37" : "" }}
                  onClick={(e) => handleFavorite(socialMedia)}
                />
              )}
            </div>
          ))}
        </CheckIfMembership>
      </div>

      <div
        className="socialmedia_container"
        style={{ marginLeft: 20, marginBottom: 40 }}
      >
        {error && <p style={{ color: "red" }}>{error}</p>}

        <h2 style={{ marginLeft: 0 }}>Escriba cómo pueden encontrarlo</h2>
        <p style={{ paddingRight: 10 }}>
          Recuerde que para las redirecciones a páginas de terceros, es
          necesario copiar el link completo con https://www/, por ejemplo
          https://www.facebook.com/, por defecto si usted copia su link de la
          barra de navegación del navegador o de la aplicación, esto aparece.
        </p>

        {pickedMedia.map((pickedIcon, index) => (
          <div key={index} className="socialmedia_input">
            <FontAwesomeIcon
              icon={pickedIcon.icon}
              style={{ color: pickedIcon.color }}
            />
            <div className="form_group" style={{ marginLeft: 15 }}>
              <label style={{ fontWeight: "300", fontSize: "14px" }}>
                Escriba su link a {pickedIcon.name}
              </label>
              <input
                type="text"
                placeholder={`${pickedIcon.placeholder}`}
                onChange={(e) => handleSocialMedia(e, pickedIcon)}
                value={findValue(pickedIcon.name)}
              />
            </div>
          </div>
        ))}
      </div>
    </form>
  );
};

export default CardModalSocialMedia;
