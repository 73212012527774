import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebar/Sidebar";

function esRutaDeCard(ruta) {
  const rutasCard = [
    "/login",
    "/register",
    "/recover_password",
    "/register_invitation/",
    "/card_template/",
    "/",
    "/user_configuration",
    "/agenda_biznes",
    "/agenda_biznes/user_profile/",
    "/my_contacts",
    "/my_contacts/user_profile/",
    "/biznes_cards",
    "/enterprise",
    "/enterprise/edit",
    "/enterprise/cards",
    "/enterprise/users",
    "/enterprise/user_profile/",
    "/enterprise/new_users",
    "/enterprise/create_role",
    "/enterprise/role_list",
    "/enterprise/edit_role/",
    "/check_suscription",
    "/membership"
  ];

  for (let rutaCard of rutasCard) {
    if (ruta.includes(rutaCard)) {
      return false;
    }
  }
  
  return true;
}

const LayoutRenderer = () => {
  const { pathname } = window.location;
  const auth = useSelector((state) => state.auth);
  const isCard = esRutaDeCard(pathname);

  return (
    <>
      <header>{isCard ? null : <Navbar />}</header>
      <Grid
        container
        sx={{
          paddingTop: "90px",
          minHeight: "100vh",
          alignContent: "space-between",
        }}
      >
        {auth && auth.logged && (
          <Grid item xs={0} md={3}>
            <Sidebar />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={auth && auth.logged ? 9 : 12}
          sx={{ padding: 5 }}
        >
          {<Outlet />}
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default LayoutRenderer;
