import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getUserById } from "../../../api/user";
import AgendaUserProfileCard from "../../agendaBiznes/AgendaUserProfileCard";
import { deleteEnterpriseUser } from "../../../api/enterprise";
import { useSnackbar } from "notistack";
import routes from "../../../router/routes";

const UserProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [cards, setCards] = useState(null);
  const auth = useSelector((state) => state.auth);
  const { id } = useParams();
  const getData = async () => {
    const res = await getUserById(id);
    if (res.status) {
      setCards(res.data.cards);
    }
  };
  useEffect(() => {
    getData();
    if (auth && auth.members[0]) {
      const find = auth.members.find((obj) => obj._id === id);
      if (find) {
        setUser(find);
      }
    }
  }, [id]);

  const handleDeleteUser = async () => {
    const res = await deleteEnterpriseUser(id);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      setTimeout(() => {
        navigate(routes.enterprise);
      }, 500);
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} xl={4}>
        {user && (
          <Card component={Paper} elevation={5}>
            <CardContent sx={{ justifyContent: "center", height: "450px" }}>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                {user.userImage && (
                  <img
                    src={user.userImage}
                    width="150px"
                    height="150px"
                    style={{ borderRadius: "50%" }}
                  />
                )}
              </Box>
              <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
                {user.firstName && user.firstName}{" "}
                {user.lastName && user.lastName}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 500 }}>Email:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{user.email && user.email}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Fecha de creación:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {user.createdAt &&
                      moment(user.createdAt).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteUser}
                  >
                    Eliminar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Grid>
      {cards &&
        cards[0] &&
        cards.map((item, index) => (
          <Grid item xs={12} md={4} xl={3} key={index}>
            <AgendaUserProfileCard template={item} userType={"enterprise"} />
          </Grid>
        ))}
    </Grid>
  );
};

export default UserProfile;
