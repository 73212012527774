import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserCard from "./users/UserCard";
import { useDispatch, useSelector } from "react-redux";
import UserRoles from "./users/UserRoles";
import { authLogin } from "../../redux/actions/auth.actions";
import { checkUser } from "../../api/auth";
import { motion } from "framer-motion";

const Users = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const users = auth.members ? auth.members : [];
  const [search, setSearch] = useState("");
  const [usersFiltered, setUsersFiltered] = useState([]);

  const identifyUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res = await checkUser();
      if (res.status) {
        dispatch(
          authLogin({
            ...res.data.userToSend,
            userType: res.data.userType,
            token,
          })
        );
      }
    }
  };

  useEffect(() => {
    identifyUser();
    const finded = users.filter((user) => {
      const name = `${user.firstName} ${user.lastName}`;
      if (
        name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
      ) {
        return user;
      }
    });

    setUsersFiltered(
      finded.map((el, index) => {
        if (index < 10) {
          return el;
        }
      })
    );
  }, [search]);
  return (
    <motion.div
      className="user_card"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={{ transform: "scale(1)" }}
      transition={{ duration: 0.45 }}
    >
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12}>
          <UserRoles />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Cantidad de usuarios: {users.length}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {usersFiltered &&
            usersFiltered[0] &&
            usersFiltered.map((user, index) => (
              <UserCard user={user} key={index} />
            ))}
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default Users;
