import {
  Card,
  CardContent,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "../api/user";
import { motion } from "framer-motion";
import routes from "../router/routes";

const UserConfig = () => {
  const auth = useSelector((state) => state.auth);
  const [privacy, setRoleName] = useState(auth.privacyType);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: auth.firstName && auth.firstName,
    lastName: auth.lastName && auth.lastName,
    description: auth.description && auth.description,
    email: auth.email && auth.email,
    password: "",
    userImage: null,
  });

  const updateForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateImagesForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files[0] });
  };

  const handleChangeSelect = (event) => {
    setRoleName(event.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("description", form.description);
    formData.append("password", form.password);
    formData.append("privacyType", privacy);
    form.firstName && formData.append("firstName", form.firstName);
    form.lastName && formData.append("lastName", form.lastName);
    form.userImage && formData.append("userImage", form.userImage);

    const res = await updateUserInfo(auth._id, formData);
    
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      window.location.reload();
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const privacyTypes = [
    { name: "Público", value: "public" },
    { name: "Privado", value: "private" },
    { name: "VIP", value: "vip"}
  ];
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item xs={12} lg={8}>
        <motion.div
          className="user_card"
          whileHover={{ scale: 1.1, cursor: "pointer" }}
          whileTap={{ scale: 0.9 }}
          animate={{ transform: "scale(1)" }}
          transition={{ duration: 0.45 }}
        >
          <Card component={Paper} elevation={5} sx={{ minHeight: 500 }}>
            <CardContent sx={{ justifyContent: "center", textAlign: "center" }}>
              <Grid container sx={{ py: 5, px: "20%", textAlign: "left" }}>
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Nombre
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="firstName"
                    value={form.firstName}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Apellido
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="lastName"
                    value={form.lastName}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Email
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="email"
                    disabled
                    value={auth.email}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Contraseña
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="password"
                    value={form.password}
                    fullWidth
                    type="password"
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    Foto de perfil
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ py: 3 }}>
                  <TextField
                    variant="standard"
                    name="userImage"
                    fullWidth
                    type="file"
                    onChange={(evt) => updateImagesForm(evt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Select
                    value={privacy}
                    defaultValue={privacyTypes[0].value}
                    onChange={handleChangeSelect}
                    fullWidth
                    sx={{ mt: 3 }}
                  >
                    {privacyTypes &&
                      privacyTypes[0] &&
                      privacyTypes.map((type, index) => (
                        <MenuItem key={index} value={type.value}>
                          <ListItemText
                            primary={type.name}
                            sx={{ textAlign: "center" }}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sx={{ py: 3, textAlign: "center" }}>
                  <button
                    className="main_button"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    Actualizar datos
                  </button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default UserConfig;
