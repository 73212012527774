import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "./routes";

const UserRoute = ({ rest }) => {
  const auth = useSelector((state) => state.auth);
  let location = useLocation();

  if (!auth.logged) {
    return <Navigate to={routes.login} state={{ from: location }} replace />;
  } else if (auth.userType !== "user") {
    return (
      <Navigate to={routes.enterprise} state={{ from: location }} replace />
    );
  }
  return <Outlet {...rest} />;
};

export default UserRoute;
