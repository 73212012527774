import React from "react";
import AppRouter from "./router/AppRouter";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import store from "./redux/store";
import "./styles/variables.css";
import "./styles/main.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#62B5E5",
    },
    secondary: {
      main: "#12174E",
    },
    otherColor: {
      main: "#999",
      yellow: "#FFE014",
    },
    text: {
      main: "#4a4a4a",
      white: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: [
      "Prompt",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={1500}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
