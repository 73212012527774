import backendApi from "./config";

const updateUserInfo = async (id, payload) => {
  try {
    const { data } = await backendApi.put(`/enterprise/${id}`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const createInvitation = async (id, email, rol, defaultLink) => {
  try {
    const { data } = await backendApi.post(`/enterprise/${id}/invite-member`, {
      email: email,
      rol: rol,
      defaultLink: defaultLink,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const createMasiveInvitation = async (id, payload) => {
  try {
    const { data } = await backendApi.post(
      `/enterprise/${id}/read-excel`,
      payload
    );
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const createNewRole = async (payload) => {
  try {
    const { data } = await backendApi.post(`/enterprise/rol`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getRoles = async (id) => {
  try {
    const { data } = await backendApi.get(`/enterprise/${id}/roles`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getRole = async (id) => {
  try {
    const { data } = await backendApi.get(`/enterprise/rol/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const editRole = async (id, payload) => {
  try {
    const { data } = await backendApi.put(`/enterprise/rol/${id}`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
/* const getEnterpriseUsers = async (id) => {
  try {
    const { data } = await backendApi.get(`/enterprise/${id}`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
} */

const deleteEnterpriseUser = async (id) => {
  try {
    const { data } = await backendApi.delete(`/enterprise/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export {
  updateUserInfo,
  createInvitation,
  createMasiveInvitation,
  createNewRole,
  getRoles,
  getRole,
  editRole,
  deleteEnterpriseUser,
};
