import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/BiznesLogo.png";
import cover from "../../assets/portadaBiznes.gif";

const TemplateCard = ({ template, setIsEditing, setRole }) => {
  console.log(template);
  return (
    <Card
      sx={{
        height: "450px",
        maxWidth: "350px",
        backgroundColor: "secondary.main",
      }}
    >
      <CardContent sx={{ height: "100%" }}>
        <Card sx={{ height: "100%" }}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Grid container sx={{ textAlign: "center" }} spacing={1}>
              <Grid item xs={12} sx={{ height: "100px", mb: "40px" }}>
                <img
                  src={template.coverPhoto ? template.coverPhoto : cover}
                  style={{
                    height: "100px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                {template.perfilImage ? (
                  <img
                    src={template.perfilImage}
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      height: "80px",
                      width: "80px",
                      transform: "translateY(-40px)",
                    }}
                  />
                ) : (
                  <img
                    src={logo}
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      height: "80px",
                      width: "80px",
                      transform: "translateY(-40px)",
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Nombre del usuario</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.main">
                  {template.jobPosition && template.jobPosition}{" "}
                  {template.jobEntity && template.jobPosition && "-"}{" "}
                  {template.jobEntity && template.jobEntity}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <button
                  className="main_button"
                  style={{ color: "black", width: "100%" }}
                  onClick={() => {
                    setRole(template.roles[0]);
                    setIsEditing(template);
                  }}
                >
                  Usar template
                </button>
              </Grid>
              <Grid item xs={12}>
                {template.roles &&
                  template.roles[0] &&
                  template.roles.map((item, index) => (
                    <FontAwesomeIcon
                      icon={faCircle}
                      color={item.color ? item.color : "#555555"}
                      size={"lg"}
                      key={index}
                    />
                  ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default TemplateCard;
