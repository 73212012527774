import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { createMemberships } from "../api/memberships";
import procesando from "../assets/procesando.png";
import routes from "../router/routes";

const CheckSuscription = () => {
  const [searchParams] = useSearchParams();
  const preapprovalId = searchParams.get("preapproval_id");
  useEffect(() => {
    createSuscription();
  }, []);
  const createSuscription = async () => {
    const res = await createMemberships(preapprovalId);
    setTimeout(() => {
      window.location.href = "/#" + routes.enterprise;
      window.location.reload();
    }, 2000);
  };
  return (
    <Grid container sx={{ textAlign: "center" }}>
      <Grid item xs={12} sx={{ mt: 5 }}>
        <img src={procesando} width="250px" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Procesando pago...</Typography>
        <Typography variant="body2">
          En breve estaremos enviando un email confirmando el estado de su
          compra, en caso de no recibir nuestro email puede contactarse con
          nuestro soporte.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CheckSuscription;
