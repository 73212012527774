import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserTemplates } from "../../api/card";
import CardModal from "./newCardModal/CardModal";
import { CardDataProvider } from "./newCardModal/CardData.provider";
import TemplateCard from "./TemplateCard";

const Templates = () => {
  const auth = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [role, setRole] = useState(null);
  useEffect(() => {
    getData();
  }, [auth]);

  const getData = async () => {
    console.log(auth);
    if (auth.enterprise && auth.enterprise._id) {
      const res = await getUserTemplates(auth._id);
      if (res.status) {
        console.log(res.data);
        setTemplates(res.data);
      }
    }
  };
  return (
    <Grid container spacing={2}>
      {templates &&
        templates[0] &&
        templates.map((item, index) => (
          <Grid item xs={12} md={4} xl={3} key={index}>
            <TemplateCard
              template={item}
              setIsEditing={setIsEditing}
              setRole={setRole}
            />
          </Grid>
        ))}
      <CardDataProvider auth={auth}>
        {isEditing && (
          <CardModal
            setIsCreating={setIsEditing}
            isEditing={isEditing}
            role={role}
          />
        )}
      </CardDataProvider>
    </Grid>
  );
};

export default Templates;
