import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCards } from "../api/card";
import BiznesCard from "../components/biznesCards/BiznesCard";
import { CardDataProvider } from "../components/biznesCards/newCard/CardData.provider";
import CardModal from "../components/biznesCards/newCard/CardModal";
import { deleteCard, addViewCard } from "../api/card";

const BiznesCardsPage = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteError, setDeleteError] = useState(false)
  const [cards, setCards] = useState([]);
  const auth = useSelector((state) => state.auth);

  const getData = async () => {
    const res = await getCards(auth._id);
    if (res.status) {
      setCards(res.cards);
    }
  };

  const handleDelete = async (e) => {
    try {
      e.preventDefault();
      const res = await deleteCard(isDeleting);
      window.location.reload();
    } catch (error) {
      setDeleteError(true)
    }
  }

  useEffect(() => {
    getData();
  }, [auth]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Tarjetas Biznes</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {cards &&
              cards[0] &&
              cards.map((item, index) => (
                <Grid item xs={12} md={4} xl={3} key={index}>
                  <BiznesCard template={item} setIsEditing={setIsEditing} setIsDeleting={setIsDeleting} />
                </Grid>
              ))}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ height: "430px", maxWidth: "350px", cursor: "pointer" }}
                onClick={() => {
                  setIsCreating(!isCreating);
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} size={"6x"} color="#777777" />
                  <Typography color={"text.main"}>
                    Agregar nueva tarjeta
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CardDataProvider auth={auth}>
        {isCreating && <CardModal setIsCreating={setIsCreating} />}

        {isEditing && (
          <CardModal setIsCreating={setIsEditing} isEditing={isEditing} />
        )}

        {isDeleting && (
          <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1000 }}>
            <Card sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", padding: "20px" }}>
              <Typography variant="h6">¿Estás seguro de que deseas eliminar esta tarjeta?</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <button
                    className="main_button"
                    style={{ color: "white", width: "100%", marginTop: 10 }} onClick={() => setIsDeleting(false)}>Cancelar</button>
                </Grid>
                <Grid item xs={6}>
                  <button
                    className="main_button"
                    style={{ color: "white", width: "100%", marginTop: 10 }} onClick={
                      handleDelete
                    }>Eliminar</button>
                </Grid>
              </Grid>

              {
                deleteError && <Typography variant="body1" style={{ color: "red", marginTop: 10, textAlign: 'center' }}>No se pudo eliminar la tarjeta</Typography>
              }
            </Card>
          </div>
        )}
      </CardDataProvider>
    </>
  );
};

export default BiznesCardsPage;
