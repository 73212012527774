import React, { useState } from "react";
import "./DesignForm.scss";
import ButtonsContactDesign from "./DesignForm/ButtonsContactDesign";
import PreviewHeaderDesign from "./DesignForm/PreviewHeaderDesign";
import SocialButtonsDesign from "./DesignForm/SocialButtonsDesign";
import UserDataDesign from "./DesignForm/UserDataDesign";
import CardDataContext from "./CardData.provider";
import { useContext } from "react";
import SaveStyleModal from "./DesignForm/SaveStyleModal";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdditionalsButtonsDesign from "./DesignForm/AdditionalsButtonsDesign";

const DesignForm = () => {
  const { resetStyles, resetSocialColors, role } = useContext(CardDataContext);
  const [saving, setSaving] = useState(false);

  const check = () => {
    const permission = role.permissions.some(
      (permission) => permission === "editDesign"
    );

    return permission;
  };

  if (check() === false)
    return (
      <form className="design_form">
        <div className="block-full">
          <p>
            No tienes acceso a esta función. <FontAwesomeIcon icon={faLock} />
          </p>
        </div>
      </form>
    );

  return (
    <form className="design_form">
      <h2 style={{ fontSize: 30 }}>DISEÑO DE SU TARJETA VIRTUAL</h2>

      <PreviewHeaderDesign />

      <UserDataDesign />

      <ButtonsContactDesign />

      <SocialButtonsDesign />

      <AdditionalsButtonsDesign />
      {saving && <SaveStyleModal setSaving={setSaving} />}

      <div className="form_buttons">
        <button
          style={{ fontSize: 16 }}
          onClick={(e) => resetStyles(e)}
          className="btn btn--primary"
        >
          Volver al estilo predeterminado
        </button>
        <button
          style={{ fontSize: 16 }}
          onClick={(e) => resetSocialColors(e)}
          className="btn btn--primary"
        >
          Restaurar colores de redes sociales
        </button>
      </div>
    </form>
  );
};

export default DesignForm;
