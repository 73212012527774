import backendApi from "./config";

//----------- TEMPLATES -----------

const createNewTemplate = async (payload) => {
  try {
    const { data } = await backendApi.post(
      `/enterprise/create-template`,
      payload
    );
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const updateCardTemplate = async (id, payload) => {
  try {
    const { data } = await backendApi.put(
      `/enterprise/update-template/${id}`,
      payload
    );
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getTemplates = async (id) => {
  try {
    const { data } = await backendApi.get(`/enterprise/${id}/templates`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getTemplateById = async (id) => {
  try {
    const { data } = await backendApi.get(`/enterprise/template/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getUserTemplates = async (id) => {
  try {
    const { data } = await backendApi.get(`/user/getAccessibleTemplates/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

//----------- CARDS -----------

const createCard = async (payload) => {
  try {
    const { data } = await backendApi.post(`/card`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const updateCard = async (id, payload) => {
  try {
    const { data } = await backendApi.put(`/card/${id}`, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getEnterpriseLastCard = async (id) => {
  try {
    const { data } = await backendApi.get(`/card/getEnterpriseLastCard/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

const getCards = async () => {
  try {
    const { data } = await backendApi.get(`/card`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getCardsFromAnEnterprise = async (id) => {
  try {
    const { data } = await backendApi.get(`/card/getCardsFromAnEnterprise/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const getCardByLink = async (id) => {
  try {
    const { data } = await backendApi.get(`/card/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const deleteCard = async (id) => {
  try {
    const { data } = await backendApi.delete(`/card/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

const lockCard = async (id) => {
  try {
    const { data } = await backendApi.put(`/card/lock/${id}`, {locked: true});
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

const unlockCard = async (id) => {
  try {
    const { data } = await backendApi.put(`/card/lock/${id}`, {locked: false});
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

// ESTADÍSTICAS	

const addViewCard = async (form) => {
    try {
        const response = await backendApi.put(`/stadistics/addViewCard`, form);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

const addClickOnEmail = async (form) => {
    try {
        const response = await backendApi.put(`/stadistics/addClickOnEmail`, form);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

const addVCardDownloaded = async (form) => {
    try {
        const response = await backendApi.put(`/stadistics/addVCardDownloaded`, form);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

const contactAdded = async (form) => {
    try {
        const response = await backendApi.put(`/stadistics/contactAdded`, form);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

const addClickSocialMedia = async (form) => {
    try {
        const response = await backendApi.put(`/stadistics/addClickSocialMedia`, form);
        console.log(form);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

const addClickButton = async (form) => {
    try {
        const response = await backendApi.put(`/stadistics/addClickButton`, form);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export {
  //TEMPLATES
  createNewTemplate,
  updateCardTemplate,
  getTemplates,
  getTemplateById,
  getUserTemplates,
  //CARDS
  createCard,
  updateCard,
  getEnterpriseLastCard,
  getCards,
  getCardByLink,
  deleteCard,
  getCardsFromAnEnterprise,
  //ESTADÍSTICAS
  addViewCard,
  addClickOnEmail,
  addVCardDownloaded,
  contactAdded,
  addClickSocialMedia,
  addClickButton,
  lockCard,
  unlockCard
};
