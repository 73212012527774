import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import QrModal from "../common/QrModal";
import cover from "../../assets/portadaBiznes.gif";
import logo from "../../assets/BiznesLogo.png";
import logoSmall from "../../assets/BiznesLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faLock, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";

const UserCard = ({ card, setIsEditing, setIsDeleting, redirectTo, buttonText, setIsBlocking, cardsFromEnterprise }) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <Card
        sx={{
          height: "550px",
          maxWidth: "350px",
          backgroundColor: "secondary.main",
        }}
      >
        <CardContent sx={{
          height: ` calc(100%)`,
          display: "flex",
          flexDirection: "column",
        }}>

          <Grid container>
            <Grid item xs={
              cardsFromEnterprise ? 4 : 6
            }>
              <Typography
                color="text.white"
                sx={{ textAlign: "center", py: 1, cursor: "pointer" }}
                onClick={() => setIsEditing(card)}
              >
                <FontAwesomeIcon icon={faEdit} color="var(--color-success)" />
              </Typography>
            </Grid>

            {
              cardsFromEnterprise && <Grid item xs={4}>
                <Typography
                  color="text.red"
                  sx={{ textAlign: "center", py: 1, cursor: "pointer" }}
                  onClick={() => setIsBlocking({
                    _id: card._id,
                    to: card.locked ? "unlock" : "lock",
                  })}
                >
                  <FontAwesomeIcon icon={
                    card.locked ? faUnlock : faLock 
                  } color="var( --button-primary-color)" />
                </Typography>
              </Grid>
            }

            <Grid item xs={
              cardsFromEnterprise ? 4 : 6
            }>

              <Typography
                color="text.red"
                sx={{ textAlign: "center", py: 1, cursor: "pointer" }}
                onClick={() => setIsDeleting(card._id)}
              >
                <FontAwesomeIcon icon={faTrash} color="var(--color-danger)" />
              </Typography>
            </Grid>
          </Grid>

          <Card sx={{ height: "100%" }}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Grid container sx={{ textAlign: "center" }} spacing={1}>
                {
                  (card.coverPhoto || card.perfilImage) && <Grid item xs={12} sx={{ height: "100px" }}>
                    {card.coverPhoto && <img
                      src={card.coverPhoto === "default" ? cover : card.coverPhoto}
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />}

                    {card.perfilImage && <img
                      src={card.perfilImage === "default" ? logo : card.perfilImage}
                      style={{
                        borderRadius: "50%",
                        objectFit: "cover",
                        height: "80px",
                        width: "80px",
                        transform: card.coverPhoto ? 'translateY(-40px)' : 'translateY(0px)',
                      }}
                    />}
                  </Grid>
                }

                {card.logoPhoto && (
                  <Grid item xs={12}>
                    <img
                      src={card.logoPhoto === "default" ? logoSmall : card.logoPhoto}
                      style={{
                        borderRadius: "50%",
                        objectFit: "cover",
                        height: "40px",
                        width: "40px",
                        transform: `translate(${card.perfilImage ? '20px' : '0px'
                          }, ${!card.coverPhoto ? !card.perfilImage ? '0px' : '-40px' : '15px'

                          })`,
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography>{card.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="text.main">
                    {card.jobPosition && card.jobPosition}{" "}
                    {card.jobEntity && card.jobPosition && "-"}{" "}
                    {card.jobEntity && card.jobEntity}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ my: 3 }}>
                  <Link to={
                    redirectTo ? `${redirectTo}/${card.cardLink}` : `/${card.cardLink}`
                  } target="_blank">
                    <button
                      className="main_button"
                      style={{ color: "black", width: "100%" }}
                    >
                      {
                        buttonText ? buttonText : "Ver tarjeta"
                      }
                    </button>
                  </Link>
                </Grid>
                <Grid item xs={12} sx={{ p: 0 }}>
                  <img
                    src={card.imageQr}
                    alt="Card Qr"
                    width={60}
                    height={60}
                    style={{
                      transform: "translate(-30px, -15px)",
                      position: "absolute",
                    }}
                    onClick={() => setModal(!modal)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      {modal && (
        <QrModal qr={card.imageQr} link={card.downloadQr} setModal={setModal} />
      )}
    </>
  );
};

export default UserCard;
