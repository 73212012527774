import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMemberships, getSuscriptionPaymentLink } from "../api/memberships";
import { motion } from "framer-motion";

const Membership = () => {
  const [selected, setSelected] = useState("mensual");
  const [isLoading, setIsLoading] = useState(false);
  const formatNumbers = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";
    return number.toString().replace(exp, rep);
  };
  const [memberships, setMemberships] = useState([]);
  const [cupon, setCupon] = useState("");
  const [paymentLink, setPaymentLink] = useState(null);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const auth = useSelector((state) => state.auth);

  const memorizedMemberships = useMemo(() => {
    if (memberships.length === 0) {
      const getData = async () => {
        const res = await getMemberships();
        if (res) setMemberships(res);
      };
      getData();
    }
    return memberships;
  }, [memberships]);

  const getLink = async (id, frecuencyPayment) => {
    setPaymentLink(null);
    setIsLoading(true)
    const res = await getSuscriptionPaymentLink(id, frecuencyPayment);
    setPaymentLink(res.link);
    setTimeout(() => {
      setIsLoading(false)
    }, 500);
  };

  return (
    <Grid container spacing={2}>
      {auth.membership.name === "Básica" && (
        <Grid item xs={12} sx={{ my: 1 }}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={8}>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                animate={{ transform: "scale(1)" }}
                transition={{ duration: 0.45 }}
              >
                <Card sx={{ backgroundColor: "#FF9800" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                      <FontAwesomeIcon icon={faCircleExclamation} size="xl" />
                      <Typography
                        variant="h5"
                        sx={{ width: "10rem", display: "inline-block" }}
                      >
                        Atención!
                      </Typography>
                      <FontAwesomeIcon icon={faCircleExclamation} size="xl" />
                    </Box>
                    <Typography variant="body1">
                      Debe comprar una membresía para poder navegar dentro de la
                      aplicación.
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button
          /* onClick={() => setSelected("mensual")} */
          disableRipple
          sx={
            selected === "mensual"
              ? { fontWeight: "bold", fontSize: 20, cursor: "default" }
              : { color: "black" }
          }
        >
          Mensual
        </Button>
        {/* /
        <Button
          onClick={() => setSelected("anual")}
          disableRipple
          sx={ 
            selected === "anual"
              ? { fontWeight: "bold", fontSize: 20 }
              : { color: "black" }
          }
        >
          Anual
        </Button> */}
      </Grid>
      {memberships &&
        memberships[0] &&
        memberships.map((item, index) =>
          item.name === "Básica" ? null : (
            <Grid item xs={12} md={4} key={index} sx={{ mb: 3 }}>
              <Card>
                <CardContent>
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        sx={{ mb: 3, textAlign: "center" }}
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        sx={{ mb: 3, textAlign: "center" }}
                      >
                        $
                        {selected === "mensual"
                          ? formatNumbers(item.price)
                          : item.price * 10}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={{ mb: 3 }}>
                        {item.description}
                      </Typography>
                    </Grid>
                    {/* {item.image && <img src={item.image} alt={item.name} />} */}
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
                        {item._id === auth.membership._id ? (
                          <Typography
                            sx={{
                              textAlign: "center",
                              borderRadius: "5px",
                              py: "5px",
                              boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            Actual
                          </Typography>
                        ) : (
                          <Button
                            variant="contained"
                            sx={[
                              selectedMembership &&
                                selectedMembership._id === item._id && {
                                  backgroundColor: "secondary.main",
                                  color: "text.white",
                                },
                              { textAlign: "center", width: "75%" },
                            ]}
                            onClick={() => setSelectedMembership(item)}
                          >
                            {selectedMembership &&
                            selectedMembership._id === item._id
                              ? "Seleccionada"
                              : "Seleccionar"}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        )}
      {
      /* <Grid
        item
        xs={12}
        md={6}
        sx={{ textAlign: "center", alignItems: "center" }}
      >
        <TextField
          variant="outlined"
          size="small"
          name="cupon"
          placeholder="Código de cupón"
          value={cupon}
          sx={{ mr: 1 }}
          onChange={(evt) => setCupon(evt.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "secondary.main",
            color: "text.white",
            textAlign: "center",
          }}
          onClick={() => {}}
        >
          Aplicar cupón
        </Button>
      </Grid> */
      }
      <Grid item xs={12} md={12} sx={{ alignItems: "center", textAlign: "center" }}>
        {paymentLink && (
          <Link to={paymentLink} target="_blank">
            <Button
              variant="contained"
              sx={{ textAlign: "center", color: "text.white" }}
            >
              Comprar
            </Button>
          </Link>
        )}
        {selectedMembership && (
          <Typography variant="body1" sx={{ display: "inline", ml: 3 }}>
            TOTAL: $
            {selected === "mensual"
              ? formatNumbers(selectedMembership.price)
              : selectedMembership.price * 10}
          </Typography>
        )}
      </Grid>
      {paymentLink && !isLoading && (
        <Grid item xs={12} sx={{ my: 3 }}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={8}>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                animate={{ transform: "scale(1)" }}
                transition={{ duration: 0.45 }}
              >
                <Card sx={{ backgroundColor: "#FF9800" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                      <FontAwesomeIcon icon={faCircleExclamation} size="xl" />
                      <Typography
                        variant="h5"
                        sx={{ width: "10rem", display: "inline-block" }}
                      >
                        Atención!
                      </Typography>
                      <FontAwesomeIcon icon={faCircleExclamation} size="xl" />
                    </Box>
                    <Typography variant="body1">
                      Al finalizar el proceso de pago debe hacer click a "Volver
                      al Sitio" para poder vincular el pago
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Membership;
