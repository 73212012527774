import React, { useState, useEffect } from "react";
import { getCardByLink, addViewCard, addClickButton, addClickOnEmail, addClickSocialMedia, addVCardDownloaded, contactAdded } from "../api/card";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/logo.png";
import logoSmall from "../assets/BiznesLogo.png";
import cover from "../assets/portadaBiznes.gif";
import SocialMedia from "../assets/CardModalSocialMedia.data";
import CustomMedia from "../assets/CardModalCustomMedia.data";

const CardPreview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [card, setCard] = useState({});
  const { id } = useParams();

  const getData = async () => {
    const res = await getCardByLink(id);
    if (res.status) {
      setCard(res.card);
      setIsLoading(false);
    } else {
      setCard(false)
      setIsLoading(false);
    }
  };

  const addClickOnEmailFn = async () => addClickOnEmail({ cardLink: id })

  const addVCardDownloadedFn = async () => addVCardDownloaded({ cardLink: id })

  const addContactAddedFn = async () => contactAdded({ cardLink: id })

  const addClickSocialMediaFn = async (socialMedia) => addClickSocialMedia({ cardLink: id, socialMedia, device: window.innerWidth < 768 ? 'mobile' : 'pc' })

  const addClickButtonFn = async (buttonId) => addClickButton({ buttonId, device: window.innerWidth < 768 ? 'mobile' : 'pc' })

  useEffect(() => {
    const startTime = Date.now();
    
    setIsLoading(true);
    getData();

    const addViewCardFn = async () => {
      try {
        // Conseguir tiempo en segundos
        const time = (Date.now() - startTime) / 1000
    
        await addViewCard({
          cardLink: id,
          time,
          device: window.innerWidth < 768 ? 'mobile' : 'pc'
        })
      } catch (error) {
        console.log(error)
      }
    }

    window.addEventListener('beforeunload', addViewCardFn)
  }, []);

  const findIconSocial = (name) => {
    let icon = SocialMedia.find((socialMedia) => socialMedia.name === name);
    if (!icon)
      icon = CustomMedia.find((socialMedia) => socialMedia.name === name);
    return icon.icon;
  };

  if (isLoading)
    return (
      <div className="loading_container">
        <h1>Loading...</h1>
        <TailSpin color="black" />
      </div>
    );

  if (!isLoading && card)
    if((!card.locked)) return (
      <div className="container_card_pc">
        <div
          className="preview_content"
          style={{
            backgroundColor: card.cardStyle.body.backgroundColor || "white",
            marginBottom: 50,
            minHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_header"
            style={{
              marginBottom: card.coverPhoto ? '80px' : "0",
              height: (card.coverPhoto || card.perfilImage) ? '30%' : '10%',
              marginTop: (card.coverPhoto || card.perfilImage) ? '0' : '20px',
            }}
          >
            {
              card.coverPhoto && <img
                src={card.coverPhoto === "default" ? cover : card.coverPhoto}
                alt="Prevista portada"
                id="preview_cover_photo"
              />
            }

            <motion.div
              animate={{ transform: "scale(1)" }}
              className="preview_header_user"
              style={{
                position: card.coverPhoto ? "absolute" : "relative",
                left: "0",
                display: "flex",
                justifyContent: card.coverPhoto ? "center" : "center",
                width: '100%'
              }}
            >
              {
                card.perfilImage && <img
                  src={card.perfilImage === "default" ? logo : card.perfilImage}
                  alt="Prevista foto de perfil"
                  id="preview_profile_photo"
                  style={{
                    ...card.cardStyle.perfilImage,
                    background: `red`
                  }}
                />
              }

              {
                card.logoPhoto && <img
                  src={card.logoPhoto === "default" ? logoSmall : card.logoPhoto}
                  alt="Prevista del logo"
                  id="preview_profile_logo"
                  style={{
                    ...card.cardStyle.logo,
                    right: card.perfilImage ? 'calc(50% - 70px)' : 'calc(50% - 30px)'
                  }}
                />
              }
            </motion.div>
          </motion.div>

          <motion.div
            animate={{ transform: "scale(1)" }}
            className="preview_body"
          >
            <motion.div
              animate={{ transform: "scale(1)" }}
              className="preview_body_user"
            >
              <h2
                id="preview_username"
                style={{
                  ...card.cardStyle.name,
                  textAlign: card.cardStyle.name.textAlign,
                }}
              >
                {card.name ? card.name : "Nombre de usuario"}
              </h2>

              {(card.jobPosition || card.jobEntity) && (
                <motion.div
                  animate={{ transform: "scale(1)" }}
                  className="preview_body_user_job"
                >
                  <h3 id="preview_job_position" style={card.cardStyle.job}>
                    {card.jobPosition && card.jobPosition}{" "}
                    {card.jobPosition && card.jobEntity && "-"}{" "}
                    {card.jobEntity && card.jobEntity}
                  </h3>
                </motion.div>
              )}

              <motion.div
                animate={{ transform: "scale(1)" }}
                className="preview_body_user_email"
              >
                <h3 id="preview_email" style={card.cardStyle.email}>
                  <a href={`mailto:${card.email}`} style={{ color: "inherit" }} onClick={addClickOnEmailFn}>
                    {card.email ? card.email : "usuario@email.com"}
                  </a>
                </h3>
              </motion.div>

              <motion.div
                animate={{ transform: "scale(1)" }}
                className="preview_body_user_biography"
              >
                <p id="preview_biography" style={card.cardStyle.biography}>
                  {card.biography || ""}
                </p>
              </motion.div>
            </motion.div>

            {card.vcardWants && (
              <motion.a
                href={card.vcard}
                animate={{ transform: "scale(1)" }}
                className="preview_body_vcard"
                style={{ cursor: "pointer" }}
                onClick={addVCardDownloadedFn}
              >
                <button
                  style={{ ...card.cardStyle.buttonVCard, cursor: "pointer" }}
                >
                  Agregar contacto
                </button>
              </motion.a>
            )}

            {card.addContact && (
              <motion.a
                href={`tel:+56${card.cellphone}`}
                animate={{ transform: "scale(1)" }}
                className="preview_body_vcard"
                style={{ cursor: "pointer" }}
                onClick={addContactAddedFn}
              >
                <button
                  style={{ ...card.cardStyle.buttonContact, cursor: "pointer" }}
                >
                  <a
                    style={{
                      color: card.cardStyle.buttonContact.color,
                      textDecoration: "none",
                    }}
                    href={`tel:+56${card.cellphone}`}
                  >
                    Llamar
                  </a>
                </button>
              </motion.a>
            )}

            {card.additionalButtons &&
              card.additionalButtons[0] &&
              card.additionalButtons.map((data, index) => (
                <a
                  href={
                    card.additionalButtons[index] &&
                    card.additionalButtons[index].link
                  }
                  target="_blank"
                  onClick={
                    () => addClickButtonFn(card.additionalButtons[index]._id)
                  }
                >
                  <motion.div
                    animate={{ transform: "scale(1)" }}
                    className="preview_body_vcard"
                  >
                    <button
                      style={
                        card.additionalButtons[index] &&
                        card.additionalButtons[index].styles && {
                          ...card.additionalButtons[index].styles,
                          cursor: "pointer",
                        }
                      }
                    >
                      <a
                        style={
                          card.additionalButtons[index] &&
                          card.additionalButtons[index].styles
                        }
                      >
                        {card.additionalButtons[index] &&
                          card.additionalButtons[index].text}
                      </a>
                    </button>
                  </motion.div>
                </a>
              ))}

            <motion.div
              animate={{ transform: "scale(1)" }}
              className="preview_body_social"
            >
              <motion.div
                animate={{ transform: "scale(1)" }}
                className="preview_body_social_icons"
              >
                <motion.div
                  animate={{ transform: "scale(1)" }}
                  className="favorites"
                >
                  {card.socialMedia &&
                    card.socialMedia[0] &&
                    card.socialMedia
                      .filter((el) => el.favorite)
                      .map((icon, index) => (
                        <a
                          href={icon.url}
                          key={index}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            borderRadius:
                              card.cardStyle.buttonSocialFavorite.borderRadius,
                            backgroundColor:
                              card.cardStyle.buttonSocialFavorite
                                .backgroundColor || icon.color,
                          }}
                          className="favorite"
                          onClick={
                            () => addClickSocialMediaFn(icon.name)
                          }
                        >
                          <FontAwesomeIcon
                            icon={findIconSocial(icon.name)}
                            style={{
                              color:
                                card.cardStyle.buttonSocialFavorite.color ||
                                icon.contrast,
                            }}
                          />
                        </a>
                      ))}
                </motion.div>

                {card.socialMedia &&
                  card.socialMedia[0] &&
                  card.socialMedia
                    .filter((el) => !el.favorite)
                    .map((icon, index) => (
                      <a
                        href={icon.url}
                        key={index}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          borderRadius:
                            card.cardStyle.buttonSocial.borderRadius,
                          backgroundColor:
                            card.cardStyle.buttonSocial.backgroundColor ||
                            icon.color,
                        }}
                        className="nofavorite"
                        onClick={
                          () => addClickSocialMediaFn(icon.name)
                        }
                      >
                        <FontAwesomeIcon
                          icon={findIconSocial(icon.name)}
                          style={{
                            color:
                              card.cardStyle.buttonSocial.color ||
                              icon.contrast,
                          }}
                        />
                      </a>
                    ))}
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    );

    if (card.locked) return (
      <div className="loading_container">
        <h1>401 - Carta no disponible</h1>
      </div>
    );

  if (!isLoading && !card)
    return (
      <div className="loading_container">
        <h1>404 - Page not found</h1>
      </div>
    );
};

export default CardPreview;
