import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCards, getCardsFromAnEnterprise } from "../../api/card";
import CardModal from "./newCardModal/CardModal";
import { CardDataProvider } from "./newCardModal/CardData.provider";
import UserCard from "./UserCard";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { deleteCard } from "../../api/card";
import { lockCard, unlockCard } from "../../api/card";
import { useSnackbar } from "notistack";

const Cards = ({redirectTo, buttonText, cardsFromEnterprise}) => {
  const auth = useSelector((state) => state.auth);
  const isCreated = true;
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [cards, setCards] = useState([]);
  const {enqueueSnackbar} = useSnackbar();
  useEffect(() => {
    getData();
  }, [auth]);

  const getData = async () => {
    if (auth.logged && !cardsFromEnterprise) {
      const res = await getCards();
      if (res.status) {
        console.log("res", res.cards);
        setCards(res.cards);
      }
    }

    if (auth.logged && cardsFromEnterprise) {
      console.log("auth", auth);
      const res = await getCardsFromAnEnterprise(auth._id);
      if (res.status) {
        setCards(res.cards);
        console.log("res", res.cards);
      }
    }
  }; 

  const handleDelete = async (e) => {
    try {
      e.preventDefault();
      const res = await deleteCard(isDeleting);
      enqueueSnackbar("Tarjeta eliminada", {variant: "success"});
      getData();
    } catch (error) {
      enqueueSnackbar("No se pudo eliminar la tarjeta", {variant: "error"});
    }
  }

  const handleBlock = async (e) => {
    try {
      const res = await lockCard(isBlocking._id);
      enqueueSnackbar("Tarjeta bloqueada", {variant: "success"});
      getData();
      setIsBlocking(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("No se pudo bloquear la tarjeta", {variant: "error"});
    }
  }

  const handleUnblock = async (e) => {
    try {
      const res = await unlockCard(isBlocking._id);
      enqueueSnackbar("Tarjeta desbloqueada", {variant: "success"});
      getData();
      setIsBlocking(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("No se pudo desbloquear la tarjeta", {variant: "error"});
    }
  }

  return (
    <Grid container spacing={2}>
      {cards &&
        cards[0] &&
        cards.map((item, index) => (
          <Grid item xs={12} md={4} xl={3} key={index}>
            <UserCard buttonText={buttonText} redirectTo={redirectTo} card={item} setIsEditing={setIsEditing} setIsDeleting={setIsDeleting} setIsBlocking={setIsBlocking} cardsFromEnterprise={cardsFromEnterprise} />
          </Grid>
        ))}

      <CardDataProvider auth={auth}>
        {isEditing && (
          <CardModal
            setIsCreating={setIsEditing}
            isEditing={isEditing}
            role={auth.roles[0]}
            isCreated={isCreated}
          />
        )}

        {isDeleting && (
          <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1000 }}>
            <Card sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", padding: "20px" }}>
              <Typography variant="h6">¿Estás seguro de que deseas eliminar esta tarjeta?</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <button
                    className="main_button"
                    style={{ color: "white", width: "100%", marginTop: 10 }} onClick={() => setIsDeleting(false)}>Cancelar</button>
                </Grid>
                <Grid item xs={6}>
                  <button
                    className="main_button"
                    style={{ color: "white", width: "100%", marginTop: 10 }} onClick={
                      handleDelete
                    }>Eliminar</button>
                </Grid>
              </Grid>

              {
                deleteError && <Typography variant="body1" style={{ color: "red", marginTop: 10, textAlign: 'center' }}>No se pudo eliminar la tarjeta</Typography>
              }
            </Card>
          </div>
        )}

        {isBlocking && (
          <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1000 }}>
            <Card sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", padding: "20px" }}>
              <Typography variant="h6">¿Estás seguro de que deseas {
                isBlocking.to === "unlock" ? "desbloquear" : "bloquear"
              } esta tarjeta?</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <button
                    className="main_button"
                    style={{ color: "white", width: "100%", marginTop: 10 }} onClick={() => setIsBlocking(false)}>Cancelar</button>
                </Grid>
                <Grid item xs={6}>
                  <button
                    className="main_button"
                    style={{ color: "white", width: "100%", marginTop: 10 }} onClick={() => {
                      isBlocking.to === "unlock" ? handleUnblock() : handleBlock();
                    }}>Aceptar</button>
                </Grid>
              </Grid>
            </Card>
          </div>
        )}
      </CardDataProvider>
    </Grid>
  );
};

export default Cards;
