import React from "react";
import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const UserCard = ({ user }) => {
  const navigate = useNavigate();
  return (
    <motion.div
      className="user_card"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={{ transform: "scale(1)" }}
      transition={{ duration: 0.45 }}
    >
      <Card
        component={Paper}
        elevation={5}
        sx={{
          minHeight: 150,
          backgroundColor: "primary.main",
          mb: 3,
          cursor: "pointer",
        }}
        onClick={() => navigate(`/enterprise/user_profile/${user._id}`)}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <img
                src={user.userImage}
                width="100px"
                height="100px"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ fontWeight: "400" }}>
                {user.firstName} {user.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h6" sx={{ fontWeight: "400" }}>
                {user.email}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              {user.roles &&
                user.roles[0] &&
                user.roles.map((rol, index) => (
                  <FontAwesomeIcon
                    key={index}
                    icon={faCircle}
                    color={rol.color}
                    style={{ marginBottom: 2 }}
                    size="lg"
                  />
                ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default UserCard;
