import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import UserCard from "../components/agendaBiznes/UserCard";
import { getAgendaBiznes } from "../api/user";

const AgendaBiznes = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  
  const getData = async () => {
    const data = await getAgendaBiznes();
    setUsers(data);
  };
  useEffect(() => {
    getData();
  }, []);
  
  const usersFiltered = useMemo(() => {
    return users.filter((user) => {
      const name = `${user.firstName} ${user.lastName}`;
      if (
        name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
      ) {
        return user;
      }
    }).slice(0, 10);
  }, [users, search]);

  return (
    <motion.div
      className="user_card"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={{ transform: "scale(1)" }}
      transition={{ duration: 0.45 }}
    >
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} lg={10}>
          <TextField
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={10}>
          {usersFiltered &&
            usersFiltered[0] &&
            usersFiltered.map((user, index) => (
              <UserCard user={user} key={index} />
            ))}
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default AgendaBiznes;
