import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../router/routes";

const UserRoles = () => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
      <Grid item xs={12} md={4}>
        <button
          className="main_button"
          onClick={() => navigate(routes.enterpriseCreateRole)}
        >
          Crear nuevo rol
        </button>
      </Grid>
      <Grid item xs={12} md={4} sx={{ textAlign: "end" }}>
        <button
          className="secondary_button"
          onClick={() => navigate(routes.enterpriseRoleList)}
        >
          Ir a listado de roles
        </button>
      </Grid>
    </Grid>
  );
};

export default UserRoles;
