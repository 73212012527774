import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import env from "../../env";

const AgendaUserProfileCard = ({ template, userType }) => {
  return (
    <Card
      sx={{
        height: "450px",
        maxWidth: "350px",
        backgroundColor: "secondary.main",
      }}
    >
      <CardContent sx={{ height: "100%" }}>
        <Card sx={{ height: "100%" }}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Grid container sx={{ textAlign: "center" }} spacing={1}>
              <Grid item xs={12} sx={{ height: "100px", mb: "40px" }}>
                <img
                  src={template.coverPhoto ? template.coverPhoto : logo}
                  style={{
                    height: "100px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={template.perfilImage ? template.perfilImage : logo}
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    height: "80px",
                    width: "80px",
                    transform: "translateY(-40px)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <img
                  src={template.logoPhoto ? template.logoPhoto : null}
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    height: "40px",
                    width: "40px",
                    transform: "translate(20px, -30px)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Nombre del usuario</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.main">
                  {template.jobPosition && template.jobPosition}{" "}
                  {template.jobEntity && template.jobPosition && "-"}{" "}
                  {template.jobEntity && template.jobEntity}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {userType === "enterprise" ?
                <Link to={`/${template.cardLink}`} target="_blank">
                  <button
                    className="main_button"
                    style={{ color: "black", width: "100%" }}
                  >
                    Ver tarjeta
                  </button>
                </Link>
                :
                <Link to={`/${template.cardLink}`} target="_blank">
                  <button
                    className="main_button"
                    style={{ color: "black", width: "100%" }}
                  >
                    Ver tarjeta
                  </button>
                </Link>
              }
              </Grid>
              <Grid item xs={12}>
                {template.roles &&
                  template.roles[0] &&
                  template.roles.map((item, index) => (
                    <FontAwesomeIcon
                      icon={faCircle}
                      color={item.color ? item.color : "#555555"}
                      size={"lg"}
                      key={index}
                    />
                  ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default AgendaUserProfileCard;