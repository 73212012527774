import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { login } from "../api/auth";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import routes from "../router/routes";
import { authLogin } from "../redux/actions/auth.actions";
import LoadingModal from "../components/common/LoadingModal";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);

  const updateForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("email", form.email);
    formData.append("password", form.password);
    const res = await login(formData);
    if (res.status) {
      enqueueSnackbar(res.message, { variant: "success" });
      dispatch(
        authLogin({
          token: res.data.token,
          userType: res.data.userType,
          ...res.data.userToSend,
        })
      );
      localStorage.setItem("token", res.data.token);
      if (res.data.userType === "enterprise") {
        navigate(routes.enterprise);
      } else {
        navigate(routes.home);
      }
    } else {
      enqueueSnackbar(res.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item xs={12} md={6} sx={{display: "flex",justifyContent: "center"}}>
          <Card component={Paper} elevation={3} sx={{ minHeight: 500, maxWidth: "650px" }}>
            <CardContent sx={{ justifyContent: "center", textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontWeight: "400", mt: 3 }}>
                Iniciar sesión
              </Typography>
              <Grid container sx={{ py: 3, textAlign: "left", justifyContent: "center" }}>
                <Grid item xs={12} md={10}>
                  <Typography variant="body">Email</Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    name="email"
                    value={form.email}
                    fullWidth
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="body" sx={{}}>
                    Contraseña
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3 }}>
                  <TextField
                    name="password"
                    value={form.password}
                    fullWidth
                    type="password"
                    onChange={(evt) => updateForm(evt)}
                  />
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 3, textAlign: "center" }}>
                  <button
                    className="secondary_button"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    Iniciar sesión
                  </button>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 2, textAlign: "center" }}>
                  <Link to={routes.passwordRecovery}>
                    <Typography variant="body">
                      ¿Olvidaste tu contraseña?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} md={10} sx={{ py: 2, textAlign: "center" }}>
                  <Link to={routes.register}>
                    <Typography variant="body">
                      ¿No tienes una cuenta? Regístrate
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isLoading && <LoadingModal />}
    </>
  );
};

export default Login;
