import React from 'react'
import styles from './StatsCard.module.scss'
import { useSelector } from 'react-redux'
import { SyncLoader } from 'react-spinners'
import Views from '../components/stats/Views'
import SocialMediaClicks from '../components/stats/SocialMediaClicks'
import ButtonsClicks from '../components/stats/ButtonsClicks'
import Device from '../components/stats/Device'
import TimeViewed from '../components/stats/TimeViewed'
import VCardsDownloaded from '../components/stats/VCardsDownloaded'
import ContactsAdded from '../components/stats/ContactsAdded'
import EmailClicks from '../components/stats/EmailClicks'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getCardByLink } from "../api/card";

function obtenerDiasIntermedios(from, to) {
  const startDate = new Date(from);
  const endDate = new Date(to);
  const days = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    days.push(new Date(currentDate).toISOString().split('T')[0].replace(/-/g, '/'));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return days;
}

export default function StatsCard() {
  const user = useSelector((state) => state.auth);
  const [card, setCard] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    from: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0]
  })
  const [labels, setLabels] = useState([])
  const { id } = useParams();

  const getData = async () => {
    const res = await getCardByLink(id);
    if (res.status) {
      setCard(res.card);
      setIsLoading(false);
    } else {
      setCard(false)
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    if (filters.from && filters.to) {
      setLabels(obtenerDiasIntermedios(filters.from, filters.to))
    }
  }, [filters])

  return (
    <main id={styles.main}>
        <section className={styles.filters} animation="appearBottom">
          <div className={styles.filter}>
            <label htmlFor="from">Desde</label>
            <input type="date" id="from" onChange={
              e => setFilters({
                ...filters,
                from: e.target.value
              })
            }
              defaultValue={
                new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0]
              }
            />
          </div>

          <div className={styles.filter}>
            <label htmlFor="to">Hasta</label>
            <input type="date" id="to" onChange={
              e => setFilters({
                ...filters,
                to: e.target.value
              })
            }
              defaultValue={
                new Date().toISOString().split('T')[0]
              }
            />
          </div>
        </ section>

        {card.views && <section className={styles.stats} animation="appearBottom">
          <Views card={card} filters={filters} labels={labels} authorized />

          <div className={styles.double}>
            <SocialMediaClicks card={card} filters={filters} labels={labels} authorized />
            <ButtonsClicks card={card} filters={filters} labels={labels} authorized />
          </div>

          <div className={styles.double}>
            <Device card={card} filters={filters} labels={labels} authorized />
            <TimeViewed card={card} filters={filters} labels={labels} authorized />
          </div>

          <div className={styles.double}>
            <VCardsDownloaded card={card} filters={filters} labels={labels} authorized />
            <ContactsAdded card={card} filters={filters} labels={labels} authorized />
          </div>

          <EmailClicks card={card} filters={filters} labels={labels} authorized/>
        </section>}

        {!card.views && isLoading && <div className={styles.loading} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '50px',
          marginBottom: '50px'
        }}>
          <SyncLoader color="#7577CD" loading={true} size={10} />
        </div>}
    </main>
  )
}
