import React, { useState, useEffect } from "react";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import env from "../../env";

const Footer = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${env.API_URL_ADMIN}/networking`)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        setError(true);
      });

    axios
      .get(`${env.API_URL_ADMIN}/categories`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  return (
    <footer style={{ textAlign: "center" }} className="footer_background">
      <div data-animation="appear" className="footer">
        <div className="footer_section">
          <div className="footer_section_title">
            <h3>Ubicación de tienda</h3>
          </div>
          <hr />
          <div className="footer_section_content">
            <p>{data.street}</p>
            <p>{data.city}</p>
            <p>{data.region}</p>
            <p>{data.email}</p>
            <p>{data.phone}</p>

            <div
              className="footer_section_content_social"
              style={{ marginTop: 5 }}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                size={"xl"}
                style={{ marginRight: 10 }}
                color="white"
              />
              <FontAwesomeIcon
                icon={faInstagram}
                size={"xl"}
                style={{ marginRight: 10 }}
                color="white"
              />
              <FontAwesomeIcon
                icon={faTwitter}
                size={"xl"}
                style={{ marginRight: 10 }}
                color="white"
              />
              <FontAwesomeIcon
                icon={faYoutube}
                size={"xl"}
                style={{ marginRight: 10 }}
                color="white"
              />
            </div>
          </div>
        </div>

        <div className="footer_section">
          <div className="footer_section_title">
            <h3>Tienda</h3>
          </div>

          <hr />

          <div className="footer_section_content">
            {categories.map((category) => {
              return (
                <Link
                  key={category._id}
                  to={`https://tienda.biznes.cl/#/products?category=${category.name}`}
                >
                  {category.name}
                </Link>
              );
            })}
          </div>
        </div>

        <div className="footer_section">
          <div className="footer_section_title">
            <h3>Atención al cliente</h3>
          </div>

          <hr />

          <div className="footer_section_content">
            <a href="https://tienda.biznes.cl/#/about-we#contact_form_container" target="_blank">Contáctanos</a>
            <Link to="https://tienda.biznes.cl/#/about-we" target="_blank">
              Sobre nosotros
            </Link>
          </div>
        </div>

        <div className="footer_section">
          <div className="footer_section_title">
            <h3>Política</h3>
          </div>

          <hr />

          <div className="footer_section_content">
            <Link to="https://tienda.biznes.cl/#/terms-and-conditions">Envío y devoluciones</Link>
            <Link to="https://tienda.biznes.cl/#/terms-and-conditions">Términos y condiciones</Link>
            <Link to="https://tienda.biznes.cl/#/terms-and-conditions">Métodos de pago</Link>
            <Link to="https://tienda.biznes.cl/#/faqs">FAQ</Link>
          </div>
        </div>
      </div>
      <div style={{ width: "70%", marginLeft: "15%" }}>
        <hr />
      </div>
      <div>
        <h3 style={{ color: "white", margin: "40px 0px" }}>
          Copyright © 2023. Proyecto Realizado por EICHE Chile
        </h3>
      </div>
    </footer>
  );
};

export default Footer;
