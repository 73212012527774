import backendApi from "./config";

const getUserContacts = async () => {
  try {
    const { data } = await backendApi.get(`/agenda`);
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const addContact = async (id) => {
  try {
    const { data } = await backendApi.put(`/agenda/add-friend`, {
      friendId: id,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

const removeContact = async (id) => {
  try {
    const { data } = await backendApi.put(`/agenda/remove-friend`, {
      friendId: id,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export { getUserContacts, addContact, removeContact };
