import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "./routes";

const EnterpriseRoutes = ({ rest }) => {
  const auth = useSelector((state) => state.auth);
  let location = useLocation();
  if (!auth.logged) {
    return <Navigate to={routes.login} state={{ from: location }} replace />;
  } else if (auth.userType !== "enterprise") {
    return <Navigate to={routes.home} state={{ from: location }} replace />;
  } else if (
    auth.userType === "enterprise" &&
    auth.membership.name === "Básica" &&
    !location.pathname.includes(routes.membership)
  ) {
    return (
      <Navigate to={routes.membership} state={{ from: location }} replace />
    );
  }
  return <Outlet {...rest} />;
};

export default EnterpriseRoutes;
