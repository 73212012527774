import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import routes from "../../router/routes";

const ThirdStep = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handleChangePassword,
}) => {
  return (
    <Grid container sx={{ py: 3, px: "20%", textAlign: "left" }}>
      <Grid item xs={12}>
        <Typography variant="body">Ingrese su nueva contraseña</Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 3 }}>
        <TextField
          name="password"
          value={password}
          type="password"
          fullWidth
          onChange={(evt) => setPassword(evt.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body">Confirmar contraseña</Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 3 }}>
        <TextField
          name="confirmPassword"
          value={confirmPassword}
          type="password"
          fullWidth
          onChange={(evt) => setConfirmPassword(evt.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ py: 3, textAlign: "center" }}>
        <button
          className="secondary_button"
          style={{ width: "100%" }}
          onClick={() => handleChangePassword()}
        >
          Enviar
        </button>
      </Grid>
      <Grid item xs={12} sx={{ py: 1, textAlign: "center" }}>
        <Link to={routes.login}>
          <Typography variant="body">Volver a inicio de sesión</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ThirdStep;
