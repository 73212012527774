import React from "react";
import routes from "../../router/routes";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const transitionDuration = 1;

  return (
    <motion.div
      transition={{ duration: transitionDuration }}
      animate={{
        opacity: 1,
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      }}
      className="dashboard_nav"
    >
      {auth.userType === "enterprise" ? (
        <>
          <img
            src={auth.logo}
            alt="perfil"
            style={{ objectFit: "cover", backgroundColor: "white" }}
          />
          <h2>{auth.name}</h2>
          <p style={{ textAlign: "center", margin: 20 }}>{auth.description}</p>
          <h4>{auth.email}</h4>

          {/* <div className="membership">
        <p>{auth.membership && auth.membership.name}</p>
      </div> */}

          {/* <div
        className="membership"
        style={{ backgroundColor: "white", padding: 10 }}
      >
        <p style={{ fontSize: 14, color: "black" }}>
          {auth.daysMembership === 0 ? (
            <Link to={routes.actualizeMembership} style={{ color: "black" }}>
              Actualiza tu membresía
            </Link>
          ) : (
            `${auth.daysMembership} días`
          )}
        </p>
      </div> */}

          <ul style={{ paddingTop: 50 }}>
            <motion.li
              transition={{ duration: transitionDuration * 2 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname.includes(routes.enterprise) ? "active" : ""
                }
                to={routes.enterprise}
              >
                Biznes empresas
              </Link>
            </motion.li>

            <motion.li
              transition={{ duration: transitionDuration * 1.4 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname.includes(routes.stats) ? "active" : ""
                }
                to={
                  `${routes.stats}?type=enterprise`
                }
              >
                Estadísticas
              </Link>
            </motion.li>

            <motion.li
              transition={{ duration: transitionDuration * 1.4 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname.includes(routes.biznesCards) ? "active" : ""
                }
                to={routes.biznesCards}
              >
                Mis tarjetas
              </Link>
            </motion.li>

            <motion.li
              transition={{ duration: transitionDuration * 1.4 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname.includes(routes.adminCards) ? "active" : ""
                }
                to={routes.adminCards}
              >
                Administrar tarjetas
              </Link>
            </motion.li>

            <motion.li
              transition={{ duration: transitionDuration * 1.4 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname.includes(routes.membership) ? "active" : ""
                }
                to={routes.membership}
              >
                Membresía
              </Link>
            </motion.li>
          </ul>
        </>
      ) : (
        <>
          <img
            src={auth.userImage}
            alt="perfil"
            style={{ objectFit: "cover", backgroundColor: "white" }}
          />
          <h2>
            {auth.firstName} {auth.lastName}
          </h2>
          <h4>{auth.email}</h4>

          <ul style={{ paddingTop: 50 }}>
            <motion.li
              transition={{ duration: transitionDuration * 1 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={location.pathname === routes.home ? "active" : ""}
                to={routes.home}
              >
                Inicio
              </Link>
            </motion.li>
            <motion.li
              transition={{ duration: transitionDuration * 1 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname.includes(routes.agendaBiznes)
                    ? "active"
                    : ""
                }
                to={routes.agendaBiznes}
              >
                Agenda Biznes
              </Link>
            </motion.li>
            <motion.li
              transition={{ duration: transitionDuration * 1 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname.includes(routes.myContacts) ? "active" : ""
                }
                to={routes.myContacts}
              >
                Mis contactos
              </Link>
            </motion.li>
            <motion.li
              transition={{ duration: transitionDuration * 1 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname === routes.userConfig ? "active" : ""
                }
                to={routes.userConfig}
              >
                Configuración de usuario
              </Link>
            </motion.li>
            <motion.li
              transition={{ duration: transitionDuration * 1 }}
              animate={{ opacity: 1, top: 0 }}
            >
              <Link
                className={
                  location.pathname === routes.stats ? "active" : ""
                }
                to={routes.stats}
              >
                Estadísticas
              </Link>
            </motion.li>
          </ul>
        </>
      )}
    </motion.div>
  );
};

export default Sidebar;
